const login_detail = (state = {}, action) => {
  switch (action.type) {
    case "loginDetails":
      return action.payload;
    default:
      return state;
  }
};

export default login_detail;
