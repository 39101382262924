import { post, get, deletes } from "./method";
import { URL } from "../constants";

// LOGIN
export const login = async (data) => await post(URL.LOGIN, data);

// LOGOUT

export const logout = async () => await get(URL.LOGOUT);

export const setPassword = async (data) => await post(URL.SETPASSWORD, data);

// export const logout = async () => await get(URL.LOGOUT);
// export const deleteSetting = async (id) =>
//   await deletes(URL.SETTING + "/" + id);

// DEPARTMENT

export const departmentAdder = async (data) => await post(URL.DEPARTMENT, data);

export const departmentList = async () => await get(URL.DEPARTMENT_LIST);

export const departmentNewList = async (params) =>
  await get(URL.DEPARTMENT_NEW_LIST + params);

export const departmentUpdate = async (id, data) =>
  await post(URL.DEPARTMENT_EDIT + id, data);

export const departmentCreate = async (data) =>
  await post(URL.DEPARTMENT_ADD, data);

// DASHBOARD

export const dashboard = async () => await get(URL.DASHBOARD);

export const dashboardAsn = async () => await get(URL.DASHBORD_ASN);

export const dashboardGrn = async () => await get(URL.DASHBORD_GRN);

export const dashboardDc = async () => await get(URL.DASHBORD_DC);

export const dashboardRn = async () => await get(URL.DASHBOARD_RN);

export const dashboardAmount = async (data) =>
  await post(URL.DASHBORD_AMOUNT, data);

export const dashboardIntransit = async () => await get(URL.DASHBOARD_INTRASIT);

export const dashboardOnDelivered = async () =>
  await get(URL.DASHBOARD_ON_DELIVERED);

export const dashboardOnGRN = async () => await get(URL.DASHBOARD_ON_GRN);

export const dashboardOnReturn = async () =>
  await get(URL.DASHBOARD_ON_RETURNS);

export const dashboardUpcoming = async () =>
  await get(URL.DASHBORD_UPCOMING_LIST);

// IN FRONTEND
export const purchaseOrderList = async (params) =>
  await get(URL.SUPPLIER_PURCHASE + params);

// ROLE

// export const get_roles = async (params) => await get(URL.ROLE + params);
export const get_roles = async (params) => await get(URL.ROLE + params);
export const get_roles_status = async (id) => await get(URL.ROLE_STATUS + id);
export const add_role = async (data) => await post(URL.ROLE_ADD, data);
export const view_roles = async (data) => await get(URL.CREATE_ROLE + data);
export const update_role = async (id, data) =>
  await post(`${URL.ROLE_EDIT}${id}`, data);
export const role_detail = async (id) =>
  await get(`${URL.ROLE_EDIT}${id}/edit`);

export const getRoles = async () => await get(URL.ROLE_ALL);

// ADMIN
export const get_admins = async (params) => await get(URL.ADMIN + params);
export const get_admin_roles = async () => await get(URL.ADMIN_ROLES);
export const add_admin = async (data) => await post(URL.ADMIN, data);
export const admin_detail = async (id) =>
  await get(`${URL.UPDATE_ADMIN}${id}/edit`);
export const update_admin = async (id, data) =>
  await post(`${URL.UPDATE_ADMIN}${id}`, data);
export const admin_status = async (id) => await get(`${URL.ADMIN_STATUS}${id}`);

// NEW SUPPLIER

export const get_new_suppliers = async (params) =>
  await get(URL.NEW_SUPPLIER_LIST + params);
export const get_factories = async () => await get(URL.FACTORIES_LIST);
export const new_supplier_status_change = async (data) =>
  await post(URL.SUPPLIER_STATUS, data);

export const factoriesChange = async (data) =>
  await post(URL.EXISTING_SUPPLIER_FACTORY_CHANGE, data);

// EXISTING SUPPLIER
export const get_existing_suppliers = async (params) =>
  await get(URL.EXISTING_SUPPLIER_LIST + params);
export const view_existing_supplier = async (id) =>
  await get(`${URL.EXISTING_SUPPLIER_VIEW}${id}`);
export const invite_supplier = async (data) =>
  await post(URL.INVITE_EXISTING_SUPPLIER, data);

// BROADCAST
export const create_broadcast = async () => await get(URL.CREATE_BROADCAST);
export const get_broadcast_list = async () => await get(URL.BROADCAST_LIST);
export const broadcast_view = async (id) =>
  await get(`${URL.VIEW_BROADCAST}${id}`);
export const add_blog_sel_filter = async (sou_id, sou_ty_id, bus_id, data) =>
  await post(
    `broadcast?source=${sou_id}&source_type=${sou_ty_id}&bussiness_source=${bus_id}`,
    data
  );

// BLOG

export const add_blog = async (id, title, des, doc, date, status) =>
  await post(`broadcast?select_all=${1}`);

export const add_blog_filter_all = async (data) =>
  await post(URL.FILTER_ALL, data);

// TYPES

export const typesList = async () => await get(URL.TYPES);
