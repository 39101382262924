import Search from "../assets/Icons/Normal Icons/Search.svg";
import { Col, Modal } from "react-bootstrap";

function GeneralFilter({ searchValue, setSearchValue }) {
  return (
    <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
      <Col
        xs={12}
        lg={8}
        md={4}
        className="d-flex align-items-center my-1 gap-2 my-md-0">
        <label>Show</label>
        <select name="" className="cont-border px-2 py-1">
          <option value="5">6</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        <label>1-5 of 20</label>
      </Col>
      <Col xs={12} lg={4} md={8} className="d-flex my-1 ms-auto my-md-0">
        <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
          <input
            placeholder="Search Title"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <img className="search-icon" src={Search} alt="searBar" />
        </div>
      </Col>
    </div>
  );
}

export default GeneralFilter;
