import { IoIosArrowRoundBack } from "react-icons/io";

import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import moment from "moment";
import FileViewer from "react-file-viewer";
import { Col, Modal } from "react-bootstrap";
import { api } from "../../../constants/Services";
import { FileUploader } from "react-drag-drop-files";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineFileJpg } from "react-icons/ai";
import { useSelector } from "react-redux";
import "../Admin.scss";

function AdminCreateBroadcastComponent() {
  const editor = useRef(null);
  const [errorModal, setErrorModal] = useState(false);
  const [viewDoc, setViewDoc] = useState(false);
  const [error, setError] = useState([]);
  const [selAll, setSelAll] = useState(false);
  const [title, setTitle] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [source, setSource] = useState("");
  const [busSource, setBusSource] = useState("");
  const [des, setDes] = useState("");
  const [doc, setDoc] = useState();
  const [date, setDate] = useState("");

  const create_broadcast_details = useSelector(
    (state) => state.create_broadcast_list_show
  );

  // console.log("err", error);

  const onError = (e) => {
    console.log("error-file", e);
  };

  let doc_view;

  if (doc) {
    let type = doc?.name.split(".");
    type = type[1];
    console.log(type);
    doc_view = (
      <FileViewer
        fileType={type}
        filePath={URL.createObjectURL(doc)}
        onError={onError}
      />
    );
  }

  const createBlogActionHandler = () => {
    let blogData = {
      title: title || "",
      description: des || "",
      documents: doc || [],
      date: date || "",
      status: 1,
    };
    if (title === "" && des === "" && date === "") {
      let temp = ["title", "date", "des"];
      setError(temp);
    } else if (title === "" || des === "" || date === "") {
      title === ""
        ? setError((error) => [...error, "title"])
        : des === ""
        ? setError((error) => [...error, "des"])
        : date === ""
        ? setError((error) => [...error, "date"])
        : setError("");
    } else {
      setError([]);
    }

    if (!selAll) {
      if ((!selAll && sourceType === "") || source === "" || busSource === "") {
        setErrorModal(true);
      } else {
        setErrorModal(false);
      }
    } else {
      setErrorModal(false);
    }
    if (
      title !== "" &&
      des !== "" &&
      date !== "" &&
      (selAll ||
        (!selAll && sourceType !== "") ||
        source !== "" ||
        busSource !== "")
    ) {
      selAll
        ? api.add_blog_filter_all(blogData).then((res) => {
            console.log("add-blog-filter-all", res);
          })
        : api
            .add_blog_sel_filter(source, sourceType, busSource, blogData)
            .then((res) => {
              // console.log("add-blog-filter-sel-res", res);
            });
    }
  };

  return (
    <>
      <div className="admin-create-bc-cont my-3 py-2">
        <div className="d-flex flex-nowrap gap-1 py-1 my-2 align-items-center">
          <Link to="/admin/broadcast/list" className="pointer fs-3 mb-2">
            <IoIosArrowRoundBack className="primary1" />
          </Link>
          <h5 className="fs-15px fnt-fam-f2 primary1  mb-0 ">
            Create New Broadcast
          </h5>
        </div>
        <div className="d-sm-flex flex-wrap py-3">
          <Col lg={4} md={12} xs={12} className="py-1 my-1 bor-right pe-4">
            <p className="fnt-fam-f2 fs-14px">
              Select Suppliers: <span className="primary1">40</span>
            </p>
            <div className="py-1 my-1 fnt-fam-f2 fs-14px d-flex-al gap-2">
              <input
                type="checkbox"
                className="pointer"
                value={selAll}
                onChange={() => setSelAll(!selAll)}
              />
              <span>Select All</span>
            </div>
            <div className="d-sm-flex flex-column">
              <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
                Source Type
              </label>
              <div className="d-sm-flex justify-content-between border-btm align-items-center">
                <select
                  className="border-0 pb-1 my-1 w-100 pointer"
                  value={sourceType}
                  onChange={(e) => setSourceType(e.target.value)}>
                  <option value={null}>Select source Types</option>
                  {create_broadcast_details?.source_types.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-sm-flex flex-column">
              <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">Source</label>
              <div className="d-sm-flex justify-content-between border-btm align-items-center">
                <select
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  className="border-0 pb-1 my-1 w-100 pointer">
                  <option value={null}>Select source</option>
                  {create_broadcast_details?.sources.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-sm-flex flex-column">
              <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
                Buisness Source
              </label>
              <div className="d-sm-flex justify-content-between border-btm align-items-center">
                <select
                  className="border-0 pb-1 my-1 fs-14px w-100 pointer"
                  value={busSource}
                  onChange={(e) => setBusSource(e.target.value)}>
                  <option value={null}>Select Business source</option>
                  {create_broadcast_details?.bussiness_sources.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="d-sm-flex flex-column">
              <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
                Category
              </label>
              <div className="d-sm-flex justify-content-between border-btm align-items-center pointer">
                <select className="border-0 pb-1 my-1fs-14px w-100 pointer">
                  <option value={null}>Select Category</option>
                  {create_broadcast_details?.categories?.map((category) => (
                    <option key={category?.id} value={category?.id}>
                      {category?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
              Material Type
            </label>
            <div className="d-sm-flex justify-content-between border-btm align-items-center">
              <select className="border-0 pb-1 my-1 no-apprence fs-14px">
                <option>Hardware</option>
              </select>
              <RiArrowDropDownLine className="fs-1r" />
            </div>
          </div> */}
            {/* <div className="d-sm-flex flex-column">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-14px">
              Material Type
            </label>
            <div className="">
              <div className="d-sm-flex justify-content-between border-btm align-items-center">
                <p className="fs-14px">$10002-Mohammed Bilal</p>

                {/* <select className="border-0 pb-1 my-1 no-apprence">
                <option>$10002-Mohammed Bilal</option>
              </select> */}
            {/* /  </div> */}
            {/* <div className="w-100 cont-border px-2 ad-create-bc-drop-cont">
                <div className="ad-table-search-box  d-flex gap-2 fnt-fam-f2 fs-13px  align-items-center my-2 px-2 rounded-1">
                  <input placeholder="Search" />
                  <img className="search-icon" src={Search} />
                </div>
                <div className="my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$10002-Mohammed Bilal</span>
                </div>
                <div className=" my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$1003-Karthick</span>
                </div>
                <div className=" my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$10003-Mohammed Bilal</span>
                </div>
                <div className=" my-1 py-1 fs-14px">
                  <input type="checkbox" />
                  <span className="ps-1 ms-1">$10005-Mohammed Madeena</span>
                </div>
              </div> */}
            {/* </div> */}
            {/* </div> */}
          </Col>
          <Col lg={8} xs={12} className="py-1 my-1 px-2">
            <div>
              <label className="fnt-fam-f2 my-1 py-1">
                BroadCast Title<span className="red-text">*</span>
              </label>
              <div className="input-group my-3 fnt-fam-f2 cont-border">
                <input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  className="form-control border-0 py-2 px-3"
                  placeholder="BroadCast Title"
                  aria-label="BroadCast Title"
                />
              </div>
            </div>
            {error.includes("title") && (
              <p className="fnt-fam-f1 red-text mb-0">Please Enter Title</p>
            )}
            <div>
              <label className="fnt-fam-f2 my-1 py-1">
                Description<span className="red-text">*</span>
              </label>
              <JoditEditor
                className="my-3 fnt-fam-f2"
                ref={editor.value}
                value={des}
                onChange={(des) => setDes(des)}
              />
            </div>
            <p className="d-sm-flex justify-content-end fs-15px t6 fnt-fam-f2">
              Max 2500
            </p>
            {error.includes("des") && (
              <p className="fnt-fam-f1 red-text mb-0">
                Please Enter Description
              </p>
            )}
            <label className="d-block py-2 my-1">
              Schedule Date<span className="red-text">*</span>
            </label>
            <Col
              lg={5}
              md={8}
              xs={12}
              className="ad-create-broadcast d-sm-flex justify-content-between border-btm me-auto">
              <span className="fs-13px">
                {date
                  ? moment(date).format("DD-MM-YYYY")
                  : moment().format("DD-MM-YYYY")}
              </span>
              <input
                type="date"
                className="border-0"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ width: "50px" }}
              />
            </Col>
            {error.includes("date") && (
              <p className="fnt-fam-f1 red-text mb-0">Please Enter Date</p>
            )}
            <Col xs={12} className="pe-3 my-2 file_upload ad-file-upload">
              <h5 className="primary1 fs-15px">Documents</h5>
              <span className="ad-file-upload-text">
                Drag and drop your files here or Upload a file
              </span>

              <FileUploader
                multiple={true}
                name="file"
                className="pad-20px"
                handleChange={(file) => setDoc(file[0])}
              />

              <span className="fs-13 fnt-fam-f2">
                Note:Format jpg,jpeg,pdf upload 5.0MB each file ,Allowed to
                upload maximum of 1 upload
              </span>
            </Col>
            {doc && (
              <div className="d-flex justify-content-between px-2 py-2 my-3 align-items-center file-upload-cont-bg wid-105-perc">
                <div className="d-flex gap-2">
                  <AiOutlineFileJpg className="text-success fs-4" />
                  {doc?.name}
                </div>
                <div className="d-sm-flex gap-3 align-items-center">
                  <h6
                    className="primary1 fs-14px fnt-fam-f2 mb-0 pointer"
                    onClick={() => setViewDoc(!viewDoc)}>
                    View
                  </h6>
                  {/* <h6 className="primary1 bg-light fs-14px fnt-fam-f2 mb-0 pointer">
                    View
                  </h6> */}
                  <span
                    className="create-inv-delete-icon-cont pointer"
                    onClick={() => setDoc()}>
                    <RiDeleteBin6Line />
                  </span>
                </div>
              </div>
            )}

            <div className="d-flex mt-3 mb-4 justify-content-end gap-3 my-2 wid-105-perc">
              <button className="clear-btn  px-3 py-1 fnt-fam-f2 border-0 rounded-1 opac_half">
                Clear
              </button>
              <button
                className="bg-primary1 bg-primary1 fnt-fam-f2 px-3 py-1 text-white rounded-1 border-0"
                onClick={createBlogActionHandler}>
                Publish
              </button>
            </div>
          </Col>
        </div>
      </div>
      <Modal
        centered
        aria-labelledby="document-viewer"
        show={errorModal}
        onHide={() => setErrorModal(!errorModal)}>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <h6 className="red-text text-center fnt-fam-f2">
            Either check sel all or sel from each branch
          </h6>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="lg"
        aria-labelledby="document-viewer"
        show={viewDoc}
        onHide={() => setViewDoc(!viewDoc)}>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>{doc_view}</Modal.Body>
      </Modal>
    </>
  );
}

export default AdminCreateBroadcastComponent;
