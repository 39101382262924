import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LogInFooter from "../../logincomponent/LogInFooter";
import GeneralFilter from "../../component/GeneralFilter";
import { api } from "../../constants/Services";
import TopBar from "../../navbar/TopBar";
import eye_icon from "../../assets/Icons/Normal Icons/Eye.svg";
import delete_icon from "../../assets/Icons/Normal Icons/Delete.svg";
import edit_icon from "../../assets/Icons/Normal Icons/Edit.svg";
import SearchIcon from "../../assets/Icons/Normal Icons/Search.svg";
import TopHeader from "../../navbar/TopHeader";
import {
  admins_list_store,
  admins_roles_store,
  admin_detail,
} from "../../constants/constants/Storage/actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorModel from "../../component/errorModel";
import ReactPaginate from "react-paginate";

function AdminsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = localStorage.getItem("permission");
  const [modalError, setModalError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [activePages, setActivePages] = useState();
  const [count, setCount] = useState(0);

  const admins = useSelector((state) => state.admins_list_show);
  const login = useSelector((state) => state.login_detail);

  const adminViewHandler = (ad_id, action) => {
    api.admin_detail(ad_id).then((res) => {
      // console.log("adminDetails", res?.admin);
      dispatch(admin_detail(res?.admin));
    });
    navigate("/admin/add", { state: { action: action } });
  };

  const get_admins_list = () => {
    let params;

    if (search !== "") {
      params = `?page=${page}&name=${search}`;
    } else {
      params = `?page=${pages}`;
    }

    api.get_admins(params).then((res) => {
      if (res?.status == "success") {
        setList(res?.admins);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res?.pagination);
        dispatch(admins_list_store(res?.admins));
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }
    setCount(selectedObject.selected);
  };

  const adminStatusHandler = (ad_id) => {
    api.admin_status(ad_id).then((res) => {
      // console.log("admin-status", res);
      if (res?.status == "success") {
        setModalError(res);
        setErrorBox(true);
        setTimeout(() => {
          get_admins_list();
        }, 800);
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const get_roles = () => {
    api.get_admin_roles().then((res) => {
      // console.log("admin-roles-res", res?.roles);
      if (res?.status == "success") {
        dispatch(admins_roles_store(res?.roles));
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    get_admins_list();
    // get_roles();
  }, [pages, search, page]);

  console.log("admins-redux", permissions?.includes("ADD-ADMINS"));

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={modalError}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type="admin-list" />
      <div className="w-90  py-3 my-2  mid-sc-res">
        <div className="d-flex  flex-wrap my-2">
          <Col xs={12} sm={12} md={11} className="px-4">
            <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
              <div className="action-page-cont">
                <p>Showing</p>
                <div className="page-status-cont">
                  <p
                    style={{
                      border: "1px solid #ced4da",
                      padding: "4px",
                      // margin: "10px 0px",
                      textAlign: "center",
                      width: "35px",
                      height: "28px",
                    }}
                  >
                    {list?.length}
                  </p>
                </div>
                <p>of {activePages?.total} Users</p>
              </div>
              <Col
                xs={12}
                lg={4}
                md={8}
                className="d-flex my-1 ms-auto my-md-0"
              >
                <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
                  <input
                    placeholder="Search Title"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img className="search-icon" src={SearchIcon} alt="searBar" />
                </div>
              </Col>
            </div>
          </Col>
          {permissions?.includes("ADD-ADMINS") ? (
            <Col xs={12} sm={12} md={1}>
              <button
                className="border-0 fs-14px fnt-fam-f2 p-2 bg-primary1 add_button text-white "
                onClick={() =>
                  navigate("/admin/add", { state: { type: "add" } })
                }
              >
                Add User
              </button>
            </Col>
          ) : null}
        </div>
        <Col style={{ minHeight: "70vh" }} className="prduct-page">
          <div className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll">
            <table className="w-100" style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th className="text-center fs-15px">S.No</th>
                  <th className="text-center fs-15px">Name</th>
                  <th className="text-center fs-15px">Email</th>
                  {/* <th className="text-center fs-15px">Mobile</th> */}
                  <th className="text-center fs-15px">Role</th>
                  <th className="text-center fs-15px">Status</th>
                  <th className="text-center fs-15px">Action</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, ind) => (
                  <>
                    <tr key={ind} className="shipment-table">
                      <td className="text-center fs-14px">
                        {ind + 1 == 10 ? count + 1 : count}
                        {ind + 1 == 10 ? 0 : ind + 1}
                      </td>
                      <td className="text-center fs-14px">{item?.name}</td>
                      <td className="text-center fs-14px">{item?.email}</td>
                      {/* <td className="text-center fs-14px">{item?.mobile}</td> */}
                      <td className="text-center fs-14px">
                        {item?.role?.code}
                      </td>
                      <td className="text-center">
                        {permissions.includes("STATUS-ADMINS") && (
                          <button
                            type="button"
                            onClick={() => adminStatusHandler(item?.id)}
                            style={{ width: "104px" }}
                            className={
                              item.status == "1"
                                ? `btn btn-outline-success  po-card-btn fs-14px`
                                : item.status == 1
                                ? `btn btn-outline-success  po-card-btn fs-14px`
                                : `btn btn-outline-danger po-card-btn fs-14px`
                            }
                          >
                            {item.status == "1"
                              ? "Active"
                              : // : item.status == 1
                                // ? "Active"
                                "InActive"}
                          </button>
                        )}
                      </td>
                      <td>
                        <div className="d-flex gap-3 action-box  justify-content-center pointer">
                          {permissions.includes("VIEW-ADMINS") && (
                            <img
                              src={eye_icon}
                              alt="eye-icon"
                              onClick={() =>
                                navigate("/admin/add", {
                                  state: { data: item, type: "view" },
                                })
                              }
                            />
                          )}

                          {/* {login?.permissions.includes("CAN-EDIT-ADMINS") && (  */}
                          {permissions.includes("EDIT-ADMINS") && (
                            <img
                              src={edit_icon}
                              alt="edit-icon"
                              onClick={() =>
                                navigate("/admin/add", {
                                  state: { data: item, type: "edit" },
                                })
                              }
                            />
                          )}
                          {/* )} */}

                          {/* {login?.permissions.includes(
                              "CAN-DELETE-ADMINS"
                            ) && <img src={delete_icon} alt="delete-icon" />} */}
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <div className="page-inner-cont py-2 d-flex justify-content-end">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default AdminsList;
