import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
import { FavLogo, Logo, ProfileImg } from "../assets/img";
import { api } from "../constants/Services";
import Navbar from "react-bootstrap/Navbar";
import { IoMdNotificationsOutline, IoMdQrScanner } from "react-icons/io";
// logout
import Dropdown from "react-bootstrap/Dropdown";
import useUser from "../constants/constants/Storage/userData";
const TopBar = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const logoutHandler = () => {
    api.logout().then((res) => {
      if (res?.status == "success") {
        localStorage.clear();
        setTimeout(() => {
          navigate("/");
        }, 800);
      }
    });
  };
  return (
    <Navbar className="w-100 top_bar d-flex-al">
      <Col md={4} xs={4}>
        <div className="logo_img">
          <Image src={Logo} />
        </div>
      </Col>
      <Col md={8} xs={8}>
        <div className="d-flex-al justify-content-end   top_link">
          {/* <Link className="noti_icon" to="/notification">
            <IoMdNotificationsOutline />
            <div className="notif-status">1</div>
          </Link>
          <Link className="d-sm-block d-none">
            <IoMdQrScanner />
          </Link> */}
          <Dropdown className="user-bg ">
            <Dropdown.Toggle className=" d-flex-al" id="dropdown-basic">
              <Link>
                <Image
                  src={FavLogo}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100%",
                  }}
                />
              </Link>
              <div className="d-md-block d-none">
                <h6 className="text-uppercase">{user?.user_name} </h6>
                {/* <p>Supplier Code 510002</p>  */}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <Link to="/profile"> Profile</Link>
              </Dropdown.Item> */}
              <Dropdown.Item>
                <Link onClick={logoutHandler}>Logout</Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
    </Navbar>
  );
};

export default TopBar;
