import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import GeneralFilter from "../../../component/GeneralFilter";
import LogInFooter from "../../../logincomponent/LogInFooter";
import AdminBlogForm from "../../../component/Admin/AdminBlogForm";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import eye_icon from "../../../assets/Icons/Normal Icons/Eye.svg";

function AdminBlogs() {
  const navigate = useNavigate();

  const dummy_data = [
    {
      country: "USA",
      state: "New York",
      city: "New York",
      status: "Active",
    },
    {
      country: "India",
      state: "Tamil Nadu",
      city: "Madurai",
      status: "Deactive",
    },
    {
      country: "Australia",
      state: "Sydney",
      city: "Sydney",
      status: "Active",
    },
    {
      country: "Russia",
      state: "Pettersburg",
      city: "Pettersburg",
      status: "Deactive",
    },
    {
      country: "Australia",
      state: "England",
      city: "London",
      status: "Active",
    },
  ];

  return (
    <>
      <TopBar />
      <TopHeader type="blog" />
      <div style={{ minHeight: "80vh" }} className="w-90 px-2 py-3 my-2">
        <div className="d-flex  flex-wrap my-2">
          <Col xs={12} sm={12} md={11}>
            <GeneralFilter />
          </Col>
          <Col xs={12} sm={12} md={1}>
            <button
              className="border-0 ms-md-3 fs-14px fnt-fam-f2 p-2 bg-primary1 add_button text-white"
              onClick={() =>
                navigate("/blog/add", { state: { action: "add" } })
              }>
              Add Blog
            </button>
          </Col>
        </div>
        <Col className="prduct-page">
          <div className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll">
            <table className="w-100" style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th className="text-center fs-15px">S.No</th>
                  <th className="text-center fs-15px">Title</th>
                  <th className="text-center fs-15px">Image</th>
                  <th className="text-center fs-15px">Description</th>
                  <th className="text-center fs-15px">Status</th>
                  <th className="text-center fs-15px">Action</th>
                </tr>
              </thead>
              <tbody>
                {dummy_data?.map((item, ind) => (
                  <>
                    <tr key={ind} className="shipment-table fs-14px fnt-fam-f1">
                      <td className="text-center">{ind + 1}</td>
                      <td className="text-center">{item?.country}</td>
                      <td className="text-center">{item?.state}</td>
                      <td className="text-center">{item?.city}</td>
                      <td className="text-center">
                        <button
                          type="button"
                          // onClick={() => adminStatusHandler(item?.id)}
                          style={{ width: "104px" }}
                          className={
                            item.status == "Active"
                              ? `btn btn-outline-success  po-card-btn fs-14px`
                              : `btn btn-outline-danger po-card-btn fs-14px`
                          }>
                          {item.status == "Active" ? "Active" : "InActive"}
                        </button>
                      </td>

                      <td className="text-center pointer">
                        <div className="d-flex justify-content-center gap-3 img_tick">
                          <img src={eye_icon} alt="eye-icon" />
                          <MdEdit
                            className="ms-1"
                            onClick={() =>
                              navigate("blog/add", {
                                state: { action: "edit" },
                              })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default AdminBlogs;
