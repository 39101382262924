import React, { useEffect, useState } from "react";
import "./App.scss";
import Loader from "./component/Loader";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { legacy_createStore as createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import allReducers from "./constants/constants/Storage/reducers";
import useToken from "./constants/constants/Storage/useToken";
import httpClient from "./constants/constants/HttpClient/httpClient";
import storage from "redux-persist/lib/storage";
import ReactRoutes from "./routes";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, allReducers);
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

let persistor = persistStore(store);

const App = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || "";
  }

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        // console.log("interceptor-config", config);
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        // disableScroll.off();
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        // console.log("http-interceptor", response);
        // setTimeout(() => {
        setLoading(false);
        // disableScroll.off();
        // }, 2000);
        return response;
      },
      function (error) {
        setLoading(false);
        // disableScroll.off();
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        {loading && <Loader />}
        <ReactRoutes />
      </PersistGate>
    </Provider>
  );
};

export default App;
