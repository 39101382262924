import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import User_Dash_Table from "./User_Dash_table";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopBar from "../../../navbar/TopBar";
import TopHeader from "../../../navbar/TopHeader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { api } from "../../../constants/Services";
import useUser from "../../../constants/constants/Storage/userData";
import moment from "moment/moment";
import ErrorModel from "../../../component/errorModel";
// import ErrorModel from "../../../constants/Services/Api/errorModel";

function Dashboard() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [currentActive, setCurrentActive] = useState(0);
  const [PODetails, setPODetails] = useState([]);
  const [tabs, setTabs] = useState(0);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [list, setList] = useState([]);
  const [asnList, setAsnList] = useState([]);
  const [grnList, setGrnList] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [rnList, setRnList] = useState([]);
  const [counts, setCounts] = useState([]);
  const [poCount, setPoCount] = useState(0);
  const [asnCount, setAsnCount] = useState(0);
  const [dcCount, setDcCount] = useState(0);
  const [rnCount, setRnCount] = useState(0);
  const [grnCount, setGrnCount] = useState(0);
  const [transit, setTransit] = useState({});
  const [delivered, setDelivered] = useState([]);
  const [grn, setGrn] = useState([]);
  const [returnData, setReturnData] = useState([]);
  const [amount, setAmount] = useState({});
  const [UpcomingList, setUpcomingList] = useState([]);

  const getList = () => {
    let param = "?page=1";

    // api.purchaseOrderList(param).then((res) => {
    //   if (res?.status == "success") {
    //     setList(res?.purchase_orders_dashboard);
    //     setCounts(res);
    //   } else {
    //     setError(res);
    //     setErrorBox(true);
    //   }
    // });

    api
      .dashboard()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.purchase_orders_dashboard);
          setCounts(res);
          setPoCount(res?.total_count);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .dashboardAsn()
      .then((res) => {
        if (res?.status == "success") {
          setAsnList(res?.asn_lists);
          setAsnCount(res?.count);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .dashboardGrn()
      .then((res) => {
        if (res?.status == "success") {
          setGrnList(res?.goods_receipt_note);
          setGrnCount(res?.total_grn);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .dashboardDc()
      .then((res) => {
        if (res?.status == "success") {
          setDcList(res?.asn_supplier_details);
          setDcCount(res?.count);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .dashboardRn()
      .then((res) => {
        if (res?.status == "success") {
          setRnList(res?.return_notes);
          setRnCount(res?.total_return_notes);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .dashboardAmount()
      .then((res) => {
        if (res?.status == "success") {
          setAmount(res);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const OngoingServices = () => {
    api
      .dashboardIntransit()
      .then((res) => {
        if (res?.status == "success") {
          setTransit(res);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .dashboardOnDelivered()
      .then((res) => {
        if (res?.status == "success") {
          setDelivered(res);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });

    api.dashboardOnGRN().then((res) => {
      if (res?.status == "success") {
        setGrn(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });

    api
      .dashboardOnReturn()
      .then((res) => {
        if (res?.status == "success") {
          setReturnData(res);
        } else {
          setError(res);
          setErrorBox(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getUpcoming = () => {
    api.dashboardUpcoming().then((res) => {
      if (res?.status == "success") {
        setUpcomingList(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getList();
    setTimeout(() => {
      OngoingServices();
    }, 2500);

    setTimeout(() => {
      getUpcoming();
    }, 3000);
  }, []);

  const dummy_data_enq = [
    // "Awarded Date",
    "S.No.",
    "KH Division",
    "PO ID",
    "PO. Ref. No",
    // "PO. Value",
    "Supplier Name",
    "Date",
    "Material Type",
    // "Delivery Date",
    "Status",
  ];
  const dummy_data_quo = [
    "S.No.",
    "KH Division",
    // "Reference Number",
    // "ASN Number",
    "ASN Date",
    // "Title",
    "PO. No.",
    "PO. Ref. No.",
    "Material Type",
    "No. of Shipments",
    // "Awarded Date",
    // "RFQ Number",
    // "Value",
    // "Status",
  ];

  const dummy_data_po = [
    // "Reference Number",
    // "Date",
    // "Title",
    // "Awarded Date",
    // "PO Value",
    // "Title",
    "S.No.",
    "KH Division",
    "DC No.",
    "DC Date",
    "PO. No.",
    "Invoice No.",
    "Material Type",
    // "ETD",
    // "ETA",
    // "Closing Date",
    // "Status",
  ];
  const dummy_data_asn = [
    // "Reference Number",
    // "Date",
    // "Title",
    // "Awarded Date",
    // "PO Number",
    // "No of Shipments",
    // "Status",
    "S.No.",
    "KH Division",
    "GRN No.",
    "GRN Date",
    // "PO. No.",
    "DC Number",
    "Material Type",
    // "OSD No.",
  ];
  const dummy_data_dc = [
    // "Reference Number",
    // "Date",
    // "Title",
    // "Awarded Date",
    // "PO Number",
    // "No of items",
    // "Status",
    "S.No.",
    "KH Division",
    "Return Note No.",
    "RN Date",
    // "PO. No.",
    "GRN No.",
    "DC No.",
    "Material",
    // "status",
  ];
  const dummy_data_grn = [
    "S.No.",
    "Reference Number",
    "Date",
    "Title",
    "Awarded Date",
    "PO. Number",
    "OSD Items",
    "Status",
  ];

  const dummy_data_rn = [
    "S.No.",
    "Reference Number",
    "Date",
    "Title",
    "Awarded Date",
    "PO. Number",
    "GRN Number",
    "Status",
  ];

  const buttons_titles = [
    // "Enqiuries (36)",
    // "Quotation (12)",
    `Purchase Order (${poCount})`,
    `Advance Shipping Notice (${asnCount})`,
    `Delivery Challan (${dcCount})`,
    `Goods Received Note (${grnCount})`,
    `Return Notes (${rnCount})`,
  ];

  let table_temp_heading;

  switch (currentActive) {
    case 0:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_enq} num={0} data={list} />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_po} />;

      break;
    case 1:
      table_temp_heading = (
        <User_Dash_Table
          dummy_data={dummy_data_quo}
          num={1}
          asnList={asnList}
        />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_asn} />;

      break;
    case 2:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_po} num={2} dcList={dcList} />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_dc} />;

      break;
    case 3:
      table_temp_heading = (
        <User_Dash_Table
          dummy_data={dummy_data_asn}
          num={3}
          grnList={grnList}
        />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_grn} />;

      break;
    case 4:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_dc} num={4} rnList={rnList} />
      );
      // table_temp_heading = <User_Dash_Table dummy_data={dummy_data_rn} />;

      break;
    case 5:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_grn} num={5} />
      );

      break;
    case 6:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_rn} num={6} />
      );

      break;
    default:
      table_temp_heading = (
        <User_Dash_Table dummy_data={dummy_data_quo} num={0} />
      );
  }
  const dashSecBtnClickHandler = (ind) => {
    setCurrentActive(ind);
  };
  let active_class;
  const renderedButtons = buttons_titles.map((title, ind) => {
    if (ind === currentActive) {
      active_class = "bg-primary1 text-white";
    } else {
      active_class = "bg-transparent";
    }
    return (
      <button
        key={ind}
        className={`${active_class} px-3 py-2 rounded-1 border-0 fs-14px fnt-fam-f1`}
        onClick={() => dashSecBtnClickHandler(ind)}
      >
        {title}
        {/* <span class="badge badge-secondary">New</span> */}
      </button>
    );
  });

  const navigation = () => {
    if (currentActive == 0) {
      navigate("/purchase-order/list");
    } else if (currentActive == 1) {
      navigate("/asn-listing");
    } else if (currentActive == 2) {
      navigate("/dc-list");
    } else if (currentActive == 3) {
      navigate("/purchase-order/goods-received-note");
    } else if (currentActive == 4) {
      navigate("/purchase-order/return-notice");
    } else {
      navigate("/dashboard");
    }
  };

  const navigations = () => {
    if (tabs == 0) {
      navigate("/purchase-order/list");
    } else if (tabs == 1) {
      navigate("/purchase-order/list");
    } else if (tabs == 2) {
      navigate("/purchase-order/goods-received-note");
    } else if (tabs == 3) {
      navigate("/purchase-order/return-notice");
    } else {
      navigate("/purchase-order/list");
    }
  };

  const style = {
    background: "#247396",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "none",
  };

  const styles = {
    background: "transparent",
    color: "#000",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "none",
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <Col>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="dashboard" />
      <TopBar />
      <div className="py-3 px-2  light-vilot-bg w-100">
        <div className="wid-92">
          <div
            style={{ width: "93%" }}
            className="d-md-flex my-1 py-1 justify-content-between mid-sc-res"
          >
            <h5 className="fnt-fam-f1 fs-15px fw-bold">Summary</h5>
          </div>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="d-md-flex flex-wrap gap-2">{renderedButtons}</div>
            {/* <button
              class="opac_half fnt-fam-f1 fs-15px"
              style={{
                cursor: "pointer",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={navigation}
            >
              View All
            </button> */}
          </div>
          {table_temp_heading}
          <div className="d-md-flex justify-content-between mid-sc-res">
            <h5 className="fnt-fam-f1 fs-15px fw-bold">
              Accounts status
              {/* (INR) */}
            </h5>
            {/* <h5 className="opac_half fnt-fam-f1 fs-15px">View Report</h5> */}
          </div>
          <div className="dashbord-card-cont p-3 d-sm-flex flex-wrap mid-sc-res">
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Total Orders:{" "}
                <span className="fw-bold">{amount?.po_count}</span>
              </p>
              <p className="fs-15px fw-bold">
                {amount?.po_total_value
                  ? Number(amount?.po_total_value).toFixed(2)
                  : "-"}
              </p>
            </div>
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Total Invoices:{" "}
                <span className="fw-bold">{amount?.invoice_count}</span>
              </p>
              <p className="fs-15px text-primary fw-bold">
                {amount?.invoice_total
                  ? Number(amount?.invoice_total).toFixed(2)
                  : "-"}
              </p>
            </div>
            {/* <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Payment Made: <span className="fw-bold">{""}</span>
              </p>
              <p className="fs-15px fw-bold text-success">{""}</p>
            </div> */}
            <div className="bor-right p-2 wid-220px me-3">
              <p className="fs-15px">
                Pending Payments: <span className="fw-bold"> </span>
              </p>
              <p className="fs-15px aqua-text fw-bold">
                {amount?.po_total_value && amount?.invoice_total
                  ? Number(
                      +amount?.po_total_value - +amount?.invoice_total
                    ).toFixed(2)
                  : "-"}
              </p>
            </div>
            <div className="p-2 wid-220px">
              <p className="fs-15px">
                Payment Overdues: <span className="fw-bold">{""}</span>
              </p>
              <p className="fs-15px fw-bold text-danger">{""}</p>
            </div>
            <div className="p-2 wid-220px">
              <p className="fs-15px">
                Base Currency: <span className="fw-bold">{""}</span>
              </p>
              <p className="fs-15px fw-bold text-info">{""}</p>
            </div>
          </div>
          <h5 className="fnt-fam-f1 fs-15px fw-bold my-2 py-1">Deliveries</h5>
          <div className="d-flex flex-wrap flex-lg-nowrap wid_lg_99">
            <Col
              xs={12}
              lg={6}
              className="dashbord-card-cont my-1 me-3 dash-btm-cards-cont py-2 d-sm-flex  flex-column flex-wrap"
            >
              <div className="py-2">
                <div className="d-md-flex justify-content-between px-3">
                  <h5 className="fnt-fam-f1 primary1 fs-15px fw-bold">
                    Ongoing Deliveries :{" "}
                    {transit?.total_asn +
                      delivered?.total_count +
                      grn?.total_grn +
                      returnData?.total_count}
                  </h5>
                  {/* <button
                    class="opac_half fnt-fam-f1 fs-15px"
                    style={{
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    onClick={navigations}
                  >
                    View All
                  </button> */}
                </div>
                <div className=" d-md-flex flex-wrap gap-2 px-3">
                  <button
                    style={tabs == 0 ? style : styles}
                    onClick={(e) => setTabs(0)}
                  >
                    In Transit ({transit?.total_asn ? transit?.total_asn : 0})
                  </button>
                  <button
                    style={tabs == 1 ? style : styles}
                    onClick={(e) => setTabs(1)}
                  >
                    Delivered (
                    {delivered?.total_count ? delivered?.total_count : 0})
                  </button>
                  <button
                    style={tabs == 2 ? style : styles}
                    onClick={(e) => setTabs(2)}
                  >
                    GRN with OSD ({grn?.total_grn ? grn?.total_grn : 0})
                  </button>
                  <button
                    style={tabs == 3 ? style : styles}
                    onClick={(e) => setTabs(3)}
                  >
                    Return (
                    {returnData?.total_count ? returnData?.total_count : 0})
                  </button>
                  {tabs == 0 ? (
                    <>
                      {" "}
                      {/* {dcList?.map((item, ind) => ( */}
                      {transit?.ongoing_in_transit_asns?.map((item, ind) => (
                        <div className="border-btm my-1 px-3 py-2" key={ind}>
                          <div className="d-md-flex py-2">
                            {/* <p className="bor-thick-right wid-141px me-3">
                              DC Date:
                              {""}
                            </p> */}
                            <p className="wid-141px">
                              Dc No:{" "}
                              {item?.ASNNo ||
                                item?.MaterialPurchaseOrderNo ||
                                item?.LeatherPurchaseOrderNo}
                            </p>
                            <p className="bor-thick-right wid-161px me-3">
                              Dc Date:{" "}
                              {item?.ETA
                                ? moment(item?.ETA).format("DD-MMM-YYYY")
                                : ""}
                            </p>
                          </div>
                          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
                            Ts-501 plane type cutting m/c 25
                          </h5> */}
                          <div className="d-md-flex py-2">
                            <p className="wid-161px me-3">
                              Material Type:{" "}
                              {item?.MaterialType == "M"
                                ? "Material"
                                : item?.MaterialType == "Material"
                                ? "Material"
                                : "Leather"}
                            </p>
                            <p className="wid-161px me-3">
                              Date of Dispatch: {/* Invoice No : */}
                              {item?.ETD
                                ? moment(item?.ETD).format("DD-MMM-YYYY")
                                : ""}
                            </p>
                            <p className="wid-161px me-3">
                              KH Division:{" "}
                              {item?.FactoryName || item?.factory?.FactoryName}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : tabs == 1 ? (
                    <>
                      {delivered?.dc_delivered?.map((item, ind) => (
                        <div className="border-btm my-1 px-3 py-2" key={ind}>
                          <div className="d-md-flex py-2">
                            <p className="bor-thick-right wid-141px me-3">
                              DC No: {item?.DCNNo}
                            </p>
                            <p className="bor-thick-right wid-141px me-3">
                              DC date:{" "}
                              {item?.DCDate
                                ? moment(item?.DCDate).format("DD-MMM-YYYY")
                                : "-"}
                            </p>
                            <p className="wid-141px">
                              ASN No:{" "}
                              {item?.LeatherASNNo
                                ? item?.LeatherASNNo
                                : item?.MaterialASNNo
                                ? item?.MaterialASNNo
                                : "-"}
                            </p>
                          </div>
                          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
                            Ts-501 plane type cutting m/c 25
                          </h5> */}
                          <div className="d-md-flex py-2">
                            <p className="wid-141px me-3">
                              KH Division: {item?.FactoryName}
                            </p>
                            <p className="wid-161px me-3">
                              ASN Date:{" "}
                              {item?.LeatherASNDate
                                ? moment(item?.LeatherASNDate).format(
                                    "DD-MMM-YYYY"
                                  )
                                : item?.MaterialASNDate
                                ? moment(item?.MaterialASNDate).format(
                                    "DD-MMM-YYYY"
                                  )
                                : "-"}
                            </p>
                            {/* <p className="wid-141px me-3">ETA: {""}</p> */}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : tabs == 2 ? (
                    <>
                      {grn?.goods_receipt_notes?.map((item, ind) => (
                        <div className="border-btm my-1 px-3 py-2" key={ind}>
                          <div className="d-md-flex  py-2">
                            <p className="bor-thick-right wid-141px me-3">
                              GRN No:{" "}
                              {item?.MaterialGoodsReceiptNoteNo ||
                                item?.LeatherGoodsReceiptNoteNo}
                            </p>
                            <p className="bor-thick-right wid-155px me-3">
                              GRN Date:{" "}
                              {item?.MaterialGoodsReceiptNoteDate
                                ? moment(
                                    item?.MaterialGoodsReceiptNoteDate
                                  ).format("DD-MMM-YYYY")
                                : item?.LeatherGoodsReceiptNoteDate
                                ? moment(
                                    item?.LeatherGoodsReceiptNoteDate
                                  ).format("DD-MMM-YYYY")
                                : "-"}
                            </p>
                            <p className="wid-141px">DC No: {item?.DCNo}</p>
                            {/* <p className="wid-141px">PO No: {""}</p> */}
                          </div>
                          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
                            Ts-501 plane type cutting m/c 25
                          </h5> */}
                          <div className="d-md-flex justify-content-between flex-wrap py-2">
                            <p className="wid-161px me-3 mt-3">
                              Material Type:{" "}
                              {item?.type == "M"
                                ? "Material"
                                : item?.type == "L"
                                ? "Leather"
                                : ""}
                            </p>
                            <p className="wid-142px me-3 mt-3">
                              Material group category: M
                            </p>
                            <p className="wid-141px me-3 mt-3">
                              Material sub category: NA
                            </p>
                            <p className="wid-171px me-3 mt-3">
                              KH division: {item?.factory?.FactoryName}
                            </p>
                            <p className="wid-151px me-3 mt-3">
                              Delivered Date: {""}
                              {item?.DCDate
                                ? moment(item?.DCDate).format("DD-MMM-YYYY")
                                : "-"}
                            </p>
                            <p className="wid-171px me-3 mt-3">
                              No of Items with OSD: {""}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : tabs == 3 ? (
                    <>
                      {returnData?.return_notes_ongoing?.map((item, ind) => (
                        <div className="border-btm my-1 py-2" key={ind}>
                          <div className="d-md-flex py-2">
                            <p className="bor-thick-right wid-141px">
                              Return Note:{" "}
                              {item?.MaterialReturnToSupplierNo ||
                                item?.LeatherReturnToSupplierNo}
                            </p>
                            <p className="bor-thick-right wid-141px">
                              GRN No: {""}
                            </p>
                            <p className="bor-thick-right wid-141px">
                              DC No: {""}
                            </p>

                            {/* <p className="wid-141px">PO No: {""}</p> */}
                          </div>
                          {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">
                            Ts-501 plane type cutting m/c 25
                          </h5> */}
                          <div className="d-md-flex justify-content-between flex-wrap py-2">
                            <p className="wid-171px mt-3">
                              Material Type:{" "}
                              {item?.MaterialReturnToSupplierNo
                                ? "Material"
                                : item?.LeatherReturnToSupplierNo
                                ? "Leather"
                                : "-"}
                            </p>
                            <p className="wid-171px mt-3">
                              Material Group Category: {""}
                            </p>
                            <p className="wid-171px  mt-3">
                              Material Sub Category: {""}
                            </p>
                            <p className="wid-151px  mt-3">
                              KH Division: {item?.FactoryName}
                            </p>
                            <p className="wid-161px  mt-3">
                              Delivered Date: {""}
                            </p>
                            <p className="wid-151px  mt-3">
                              No of Items Returned: {""}
                            </p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {/* <p className="dash-card-choice-cont py-1 dash-choice-active">
                    In Transit (12)
                  </p>
                  <p className="dash-card-choice-cont py-1">Delivered</p>
                  <p className="dash-card-choice-cont py-1">GRN with OSD</p>
                  <p className="dash-card-choice-cont py-1">Return</p> */}
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="dashbord-card-cont  my-1 dash-btm-cards-cont py-2 d-sm-flex  flex-column flex-wrap"
            >
              <div className="d-md-flex justify-content-between px-3 py-2 border-btm w-100 h-4r">
                <h5 className="fnt-fam-f1 primary1 fs-15px fw-bold">
                  Upcoming Deliveries : {UpcomingList?.total_asn}
                </h5>
                {/* <button
                  class="opac_half fnt-fam-f1 fs-15px"
                  style={{
                    cursor: "pointer",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={() => navigate("/dc-list")}
                >
                  View All
                </button> */}
              </div>
              {UpcomingList?.upcoming_deliveries_asns?.map((item, ind) => {
                return (
                  <div className="border-btm w-100 my-1 px-3 py-2" key={ind}>
                    <div className="d-md-flex py-2">
                      <p className="bor-thick-right wid-141px me-3">
                        PO No:{" "}
                        {item?.MaterialPurchaseOrderNo ||
                          item?.LeatherPurchaseOrderNo}
                      </p>
                      <p className="bor-thick-right wid-141px me-3">
                        ASN No: {item?.ASNNo}
                      </p>
                      <p className="bor-thick-right wid-141px me-3">
                        Shipment No:{""}
                      </p>
                      {/* <p className="wid-141px me-3">No of Items:{""}</p> */}
                    </div>
                    {/* <h5 className="fnt-fam-f1 fs-15px fw-bold">Bonded leather</h5>{" "} */}
                    <div className="d-md-flex py-1">
                      <p className="wid-141px me-3">
                        KH Division: {item?.FactoryName}
                      </p>
                      <p className="wid-141px me-3">
                        ETD:{" "}
                        {item?.ETD
                          ? moment(item?.ETD).format("DD-MMM-YYYY")
                          : "-"}
                      </p>
                      <p className="wid-141px me-3">
                        ETA:{" "}
                        {item?.ETA
                          ? moment(item?.ETA).format("DD-MMM-YYYY")
                          : "-"}
                      </p>
                    </div>
                  </div>
                );
              })}
            </Col>
          </div>
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </Col>
  );
}

export default Dashboard;
