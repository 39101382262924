import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import ErrorModel from "../errorModel";

const AddDescription = ({ show, setShow, type, getDepartment }) => {
  // console.log(type);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);

  const department_Add = () => {
    let payload = {
      name: name,
    };
    if (type == "department") {
      // payload.type = 3;

      setBtn(true);
      api.departmentCreate(payload).then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          setError(res);
          setShow(false);
          setErrorBox(true);
          getDepartment();
          // navigate("/enterprise-admin/userlist/department");
        } else {
          setError(res);
          setErrorBox(true);
          setBtn(false);
        }
      });
    } else {
      payload.type = 2;

      setBtn(true);
      api.departmentAdder(payload).then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          setError(res);
          setShow(false);
          setErrorBox(true);
          getDepartment();
          // navigate("/enterprise-admin/userlist/department");
        } else {
          setError(res);
          setErrorBox(true);
          setBtn(false);
        }
      });
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        className="depart-design-input-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title" />
        </Modal.Header>
        <Modal.Body className="descrioption-input">
          <h5>
            Add {type == "department" && "Department"}{" "}
            {type == "factory" && "Factory"}
          </h5>
          <h6>
            {type == "department" && "Department"}
            {type == "factory" && "Factory"} Name
          </h6>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Modal.Body>
        <div className="px-3 w-100">
          <button
            onClick={department_Add}
            className="cust-btn bg-primary1 py-2 text-white w-100"
            disabled={btn ? true : false}
          >
            Add {type == "department" && "Department"}
            {type == "factory" && "Factory"}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AddDescription;
