import { RiArrowUpDownFill } from "react-icons/ri";
import { FaEllipsisV } from "react-icons/fa";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CiSearch } from "react-icons/ci";
import { useSelector, useDispatch } from "react-redux";
import { broadcast_detail_store } from "../../../constants/constants/Storage/actions";
import { api } from "../../../constants/Services";
function Ad_BroadCast_Table_component() {
  const [dropShow, setDropShow] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const broadcast_list = useSelector((state) => state.broadcast_list_show);
  const login = useSelector((state) => state.login_detail);
  // console.log("login", login);
  console.log("broaca-list", broadcast_list);
  const dropDownMenuClickHandler = (ind) => {
    if (dropShow === ind) {
      setDropShow("");
    } else {
      setDropShow(ind);
    }
  };

  const broadcastActionViewHandler = (b_id) => {
    api.broadcast_view(b_id).then((res) => {
      navigate("/admin/broadcast/view");
      dispatch(broadcast_detail_store(res));
    });
  };

  return (
    <div className="mid-sc-res my-2 py-1">
      <div className="over-flow-x-scroll my-2 w-100">
        <table className="table table-scroll-horizontal  table-borderless min-w-1kpx">
          <thead className="fs-14px bg-light-acqu fnt-fam-f1 ad-dash-th-hgt">
            <tr>
              <th className="ps-4">
                Title
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4 text-nowrap">
                Created on
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4 text-nowrap">
                Scheduled on
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              {/* <th className="ps-4 text-nowrap">
                Targeted Stakeholder
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th> */}
              <th className="ps-4 text-nowrap">
                Status
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
              <th className="ps-4 text-center">
                Action
                <span className="fs-14px mb-1 ms-2">
                  <RiArrowUpDownFill />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  style={{ width: "302px" }}
                  className="ad-table-search-box  d-flex gap-2 fnt-fam-f2 fs-13px ms-3 align-items-center px-2 rounded-1">
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "114px" }}
                  className="ad-table-search-box ms-3 d-flex gap-2 align-items-center px-2 rounded-1">
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "114px" }}
                  className="ad-table-search-box fs-13px ms-3 d-flex gap-2 align-items-center px-2 rounded-1">
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "154px" }}
                  className="ad-table-search-box fs-13px ms-3 d-flex gap-2 align-items-center px-2 rounded-1">
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
              <td>
                <div
                  style={{ width: "83px" }}
                  className="ad-table-search-box d-flex  m-auto align-items-center px-1 fs-13px rounded-1">
                  <input placeholder="Search" />
                  <CiSearch />
                </div>
              </td>
            </tr>
            {broadcast_list.map((item, ind) => {
              return (
                <tr
                  key={ind}
                  style={{ lineHeight: "inherit" }}
                  className="fs-14px fnt-fam-f1 dash-tr-hgt border-btm">
                  <td className="ps-4">{item.title}</td>

                  <td className="ps-4">
                    {moment(item.created_at).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td className="ps-4">
                    {moment(item.schedule_on).format(
                      "dddd, MMMM Do YYYY, h:mm:ss a"
                    )}
                  </td>

                  <td className="ps-4 ">
                    {item.status == 1 ? (
                      <button className="text-white mx-auto border-0 ad-bc-table-btn light-green-bg">
                        Scheduled
                      </button>
                    ) : (
                      <button className="text-white mx-auto border-0 ad-bc-table-btn bg-dark">
                        Draft
                      </button>
                    )}
                  </td>
                  <td className="ps-4 position-relative text-center">
                    <button
                      className="cust-btn dropdown-container-tool p-0"
                      onClick={() => dropDownMenuClickHandler(ind)}>
                      <FaEllipsisV />
                      {dropShow === ind && (
                        <div className="ad-bc-tb-dropdown pointer">
                          <p
                            onClick={() => broadcastActionViewHandler(item?.id)}
                            className="fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont primary2">
                            View
                          </p>
                          <p className="text-wht fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont">
                            Edit
                          </p>
                          <p className="text-wht fnt-fam-f2 fs-13px p-2 my-2 ad-bc-tb-drop-down-inner-cont">
                            Delete
                          </p>
                        </div>
                      )}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Ad_BroadCast_Table_component;
