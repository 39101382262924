const existing_supplier_list_show = (state = [], action) => {
  switch (action.type) {
    case "existing_suppliers_list_data":
      return action.payload;
    default:
      return state;
  }
};

export default existing_supplier_list_show;
