import { combineReducers } from "redux";

import roleList from "./rolesList";
import roleDetail from "./roleDetail";
import viewRoles from "./viewRoles";
import adminDetail from "./adminDetail";
import admins_list_show from "./adminsList";
import login_detail from "./LoginDetail";
import admins_roles_show from "./adminActiveRoles";
import new_supplier_list_show from "./newSupplierList";
import existing_supplier_list_show from "./existingSupplierList";
import factories_list from "./factoriesList";
import broadcast_list_show from "./broadCastList";
import create_broadcast_list_show from "./createBroadCast";
import broadcastDetail_show from "./broadCastDetail";
import existingSupplierDetails from "./existingSupplierDetails";

const allReducers = combineReducers({
  login_detail: login_detail,
  roleList: roleList,
  viewRoles: viewRoles,
  roleDetail: roleDetail,
  admins_list_show: admins_list_show,
  adminDetail: adminDetail,
  admins_roles_show: admins_roles_show,
  new_supplier_list_show: new_supplier_list_show,
  factories_list: factories_list,
  broadcast_list_show: broadcast_list_show,
  broadcastDetail_show: broadcastDetail_show,
  create_broadcast_list_show: create_broadcast_list_show,
  existing_supplier_list_show: existing_supplier_list_show,
  existingSupplierDetails: existingSupplierDetails,
});

export default allReducers;
