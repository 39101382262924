export const errorDetails = (data) => {
  return {
    type: "errorDetails",
    payload: data,
  };
};

export const loginDetails = (data) => {
  return {
    type: "loginDetails",
    payload: data,
  };
};

export const rolesList = (data) => {
  return {
    type: "saveRolesList",
    payload: data,
  };
};

export const view_roles = (data) => {
  return {
    type: "viewRoles",
    payload: data,
  };
};

export const role_detail = (data) => {
  return {
    type: "roleDetail",
    payload: data,
  };
};

export const admins_list_store = (data) => {
  return {
    type: "admins_list_store",
    payload: data,
  };
};

export const admins_roles_store = (data) => {
  return {
    type: "admins_roles_store",
    payload: data,
  };
};

export const admin_detail = (data) => {
  return {
    type: "adminDetail",
    payload: data,
  };
};
export const existing_supplier_detail = (data) => {
  return {
    type: "existingSupplierDetails",
    payload: data,
  };
};

export const new_suppliers_list_store = (data) => {
  return {
    type: "new_suppliers_list_store",
    payload: data,
  };
};

export const existing_suppliers_list_redux_store = (data) => {
  return {
    type: "existing_suppliers_list_data",
    payload: data,
  };
};

export const factories_list_store = (data) => {
  return {
    type: "factories_list_store",
    payload: data,
  };
};

export const create_broadcast_list_store = (data) => {
  return {
    type: "create_broadcast_list_store",
    payload: data,
  };
};

export const broadcast_list_data = (data) => {
  return {
    type: "broadcast_list_store",
    payload: data,
  };
};

export const broadcast_detail_store = (data) => {
  return {
    type: "broadcastDetail",
    payload: data,
  };
};
