const broadcastDetail_show = (state = [], action) => {
  switch (action.type) {
    case "broadcastDetail":
      return action.payload;
    default:
      return state;
  }
};

export default broadcastDetail_show;
