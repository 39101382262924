import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";

const Layout = () => {
  const [outletWidth, setOutletWidth] = useState(true);
  return (
    <div className="d-flex flex-wrap">
      <Navbar outletWidth={outletWidth} setOutletWidth={setOutletWidth} />
      <div
        className="rit-body"
        style={{
          // marginLeft: outletWidth ? "47px" : "210px",
          marginLeft: "47px",
          transition: "0.2s all linear",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
