import React, { useState } from "react";
import { Col, Form, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ErrorModel from "../../errorModel";
import { Logo, showPwdImg, hidePwdImg, LoginVideo } from "../../../assets/img";
import LogInFooter from "../../../logincomponent/LogInFooter";
import { api } from "../../../constants/Services/";
import { loginDetails } from "../../../constants/constants/Storage/actions";
import useToken from "../../../constants/constants/Storage/useToken";
import httpClient from "../../../constants/constants/HttpClient/httpClient";
import { useDispatch } from "react-redux";
import useUser from "../../../constants/constants/Storage/userData";
// import LogInConnect from "../../../logincomponent/LogInConnect";
const AdminLogIn = () => {
  const navigate = useNavigate();
  const [pwd, setPwd] = useState("");
  const { token, setToken } = useToken();
  const { setUser } = useUser();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const dispatch = useDispatch();
  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };
  const [errorMessage, setErrorMessage] = useState("");

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPwd(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPwd(new_pass);
    }
  };

  const submitHandle = () => {
    let formData = new FormData();
    if (name?.length == 0 || pwd?.length == 0) {
      setNameErr(true);
      setPassErr(true);
    } else if (errorMessage?.length == 0) {
      formData.append("user_name", name);
      formData.append("password", pwd);
      api.login(formData).then((res) => {
        if (res?.status === "success") {
          dispatch(loginDetails(res));
          localStorage.setItem("permission", JSON.stringify(res?.permissions));
          setToken(res?.token);
          setUser(res?.admin);
          httpClient.defaults.headers.common["Authorization"] =
            `Bearer ${res?.token}` || "";
          setError(res);
          setErrorBox(true);
          setTimeout(() => {
            navigate("/admin/dashboard");
            window.location.reload();
          }, 800);
        } else {
          setError(res);
          setErrorBox(true);
        }
      });
    }
  };

  return (
    <>
      {/* <div className="login-bg">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <div className="d-flex-al-jc  log_cent">
          <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
            <div className="form_box">
              <Image src={Logo} />
              <h6>Supplier Portal Super Admin</h6>
              <Form>
                <input
                  className="form-control"
                  type="name"
                  placeholder="User Name"
                  onChange={(e) => setName(e.target.value)}
                />
                {name?.length == 0 && nameErr && (
                  <span style={{ color: "red" }}>Enter the Username</span>
                )}
                <div className="pwd-container">
                  <input
                    name="pwd"
                    className="form-control"
                    placeholder="Enter Password"
                    type={isRevealPwd ? "text" : "password"}
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                  />
                  <Image
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                  {pwd?.length < 8 && passErr && (
                    <span style={{ color: "red" }}>Enter 8 digit Password</span>
                  )}
                </div>
                <Button onClick={submitHandle} className="bt-tx">
                  Login
                </Button>
              </Form>
            </div>
          </Col>
        </div>
        <LogInFooter />
      </div> */}
      <div className="login-bg">
        {errorBox && (
          <ErrorModel
            error={error}
            errorBox={errorBox}
            errorModalClose={errorModalClose}
          />
        )}
        <div className="video-bg">
          <video autoPlay loop muted className="">
            <source src={LoginVideo} type="video/mp4" />
          </video>
          <div className="d-flex-al-jc log_cent">
            <Col md={6} sm={8} xs={12} lg={4} xl={3} className="">
              <div className="form_box">
                <Image src={Logo} />
                <h6>Supplier Portal KH Admin</h6>
                <div>
                  <Col xs={12} className="position-relative pb-2">
                    <input
                      className="form-control"
                      type="name"
                      placeholder="User Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                    {name?.length == 0 && nameErr && (
                      <span className="err-input">Enter User Name</span>
                    )}
                  </Col>
                  <Col xs={12} className="pwd-container position-relative pb-2">
                    <input
                      name="pwd"
                      className="form-control"
                      placeholder="Enter Password"
                      type={isRevealPwd ? "text" : "password"}
                      value={pwd}
                      onChange={handlePassword}
                    />
                    <Image
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? showPwdImg : hidePwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    />
                    {pwd?.length == 0 && passErr && (
                      <span className="err-input">Enter Password</span>
                    )}
                    {errorMessage && (
                      <p className="err-input mt-5">{errorMessage}</p>
                    )}
                  </Col>
                  {/* {errorMessage && (
                    <p className="err-input-new-err mt-4">{errorMessage}</p>
                  )} */}
                  <button
                    style={{ background: "#247395" }}
                    onClick={submitHandle}
                    className="bt-tx"
                  >
                    Login
                  </button>
                </div>
              </div>
            </Col>
          </div>
        </div>

        <LogInFooter />
      </div>
    </>
  );
};
export default AdminLogIn;
