import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DrivenLogo } from "../assets/img";

const LogInFooter = () => {
  return (
    <div className="d-flex-al-jb fot_des">
      <Col md={4} xs={12}>
        <Link to="/privacy">Privacy Statement</Link>
        <span>|</span>
        <Link to="/terms">Terms of Use</Link>
      </Col>
      <Col md={4} xs={12}>
        <Link to="#">@2022 KH Groups. All Rights Reserved.</Link>
      </Col>
      <Col md={4} xs={12}>
        <Link to="#">
          Developed by <Image src={DrivenLogo} />
        </Link>
      </Col>
    </div>
  );
};
export default LogInFooter;
