import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ErrorModel from "../errorModel";
import TopBar from "../../navbar/TopBar";
import LogInFooter from "../../logincomponent/LogInFooter";
import TopHeader from "../../navbar/TopHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { api } from "../../constants/Services";
import AddDescription from "./AddDescription";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  validMobilNo,
  EMAIL_VALIDATE,
} from "../../constants/Services/constants";

function AdminAddForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const type = location?.state?.type;
  const display = location?.state?.action;
  const [roleList, setRoleList] = useState([]);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);
  const [departShow, setDepartShow] = useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [roleErr, setRoleErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [btn, setBtn] = useState(false);
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [desData, setDesData] = useState([]);
  const [depData, setDepData] = useState([]);
  const [departmentErr, setDepartmentErr] = useState(false);
  const [designationErr, setDesignationErr] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);

  const active_roles = useSelector((state) => state.admins_roles_show);
  const admin = useSelector((state) => state.adminDetail);
  const login_detail = useSelector((state) => state.login_detail);

  const getRoles = () => {
    api.getRoles().then((res) => {
      if (res?.status == "success") {
        let temp = []
      temp=res?.roles_all?.filter((item=>item?.status==1))
      
        setRoleList(temp);
        // setRoleList(res?.roles_all);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const get_factores = () => {
    let temp = [];
    api.get_factories().then((res) => {
      if (res?.status == "success") {
        let responce = res?.factories?.filter((i) => i?.id !== 1);
        if (responce) {
          responce?.map((item) => {
            temp.push(item);
          });

          setDesData(temp);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getView = () => {
    api.admin_detail(data?.id).then((res) => {
      if (res?.status == "success") {
        setEmail(res?.admin?.email);
        setName(res?.admin?.user_name);
        setMobile(res?.admin?.mobile_code + res?.admin?.mobile);
        setRole(res?.admin?.role?.id);
        setFirstName(res?.admin?.first_name);
        setLastName(res?.admin?.last_name);
        setDepartment(res?.admin?.department);
        setDesignation(res?.admin?.factory);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const getDepartment = () => {
    api.departmentList().then((res) => {
      if (res?.status == "success") {
        setDepData(res?.admin_departments);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getRoles();
    get_factores();
    getDepartment();
    if (data) {
      getView();
    }
  }, []);

  // useEffect(() => {
  //   if (display !== "add") {
  //     setEmail(admin?.email);
  //     setName(admin?.user_name);
  //     setMobile(admin?.mobile);
  //     setRole(admin?.role?.id);
  //   }
  // }, [display]);

  // console.log("active-roles-adming-form", active_roles);
  // console.log("admin-detail", admin);
  // console.log("err", error);

  const departShowHandler = () => {
    setShow(false);
    setDepartShow(true);
  };
  const designationShowHandler = () => {
    setShow(true);
    setDepartShow(false);
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const submitHandle = () => {
    let formdata = new FormData();
    if (data) {
      if (
        name?.length == 0 ||
        mobile?.length == 0 ||
        role?.length == 0 ||
        email?.length == 0 ||
        department?.length == 0 ||
        designation?.length == 0 ||
        firstName?.length == 0 ||
        lastName?.length == 0
      ) {
        setDepartmentErr(true);
        setDesignationErr(true);
        setLastNameErr(true);
        setFirstNameErr(true);
        setNameErr(true);
        setMobileErr(true);
        setEmailErr(true);
        setRoleErr(true);
      } else {
        formdata.append("name", name);
        formdata.append("role_id", role);
        formdata.append("email", email);
        formdata.append("mobile", mobile);
        formdata.append("user_name", name);
        formdata.append("factory", designation);
        formdata.append("department", department);
        formdata.append("first_name", firstName);
        formdata.append("last_name", lastName);
        formdata.append("_method", "PUT");

        setBtn(true);
        api.update_admin(data?.id, formdata).then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            setError(res);
            setErrorBox(true);
            setTimeout(() => {
              navigate("/admin/list");
            }, 800);
          } else {
            setBtn(false);
            setError(res);
            setErrorBox(true);
          }
        });
      }
    } else {
      if (
        name?.length == 0 ||
        mobile?.length == 0 ||
        role?.length == 0 ||
        email?.length == 0 ||
        department?.length == 0 ||
        designation?.length == 0 ||
        firstName?.length == 0 ||
        lastName?.length == 0
      ) {
        setDepartmentErr(true);
        setDesignationErr(true);
        setLastNameErr(true);
        setFirstNameErr(true);
        setNameErr(true);
        setMobileErr(true);
        setEmailErr(true);
        setRoleErr(true);
        // setPasswordErr(true);
        // setConfirmPasswordErr(true);
      } else {
        formdata.append("name", name);
        formdata.append("role_id", role);
        formdata.append("email", email);
        formdata.append("department", department);
        formdata.append("user_name", name);
        formdata.append("factory", designation);
        formdata.append("first_name", firstName);
        formdata.append("last_name", lastName);

        if (mobile?.length == 12) {
          formdata.append("mobile", mobile?.slice(-10));
          formdata.append("mobile_code", mobile?.slice(0, 2));
        } else {
          formdata.append("mobile", mobile?.slice(3, mobile?.length));
          formdata.append("mobile_code", mobile?.slice(0, 3));
        }

        setBtn(true);
        api.add_admin(formdata).then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            setError(res);
            setErrorBox(true);
            setTimeout(() => {
              navigate("/admin/list");
            }, 800);
          } else {
            setBtn(false);
            setError(res);
            setErrorBox(true);
          }
        });
      }
    }
  };

  const formValidation = (data) => {
    let temp = [];
    Object.entries(data).forEach((item) => {
      temp.push(item[0]);
      if (item[1]) {
        switch (item[0]) {
          case "mobile":
            validMobilNo.test(+item[1]) &&
              temp.splice(temp.indexOf(item[0]), 1);
            break;
          case "email":
            EMAIL_VALIDATE.test(item[1]) &&
              temp.splice(temp.indexOf(item[0]), 1);

            break;
          case "password_confirmation":
            password === item[1] && temp.splice(temp.indexOf(item[0]), 1);
            break;
          default:
            temp.splice(error.indexOf(item[0]), 1);
        }
      }
    });
    setErrors(temp);
    return temp;
  };

  const adminClickHandler = () => {
    let data = {
      name: name || "",
      password: password || "",
      role_id: +role || null,
      email: email || "",
      mobile: mobile || "",
      password_confirmation: confirmPassword || "",
    };

    // formValidation(data);
    let isError = formValidation(data);
    data.user_name = name || "";
    if (isError.length === 0 && error.length === 0) {
      if (display === "add") {
        api.add_admin(data).then((res) => {
          if (res?.status === "success") {
            setError(res);
            setError(true);
            setTimeout(() => {
              navigate("/admin/list");
            }, 800);
          } else {
            setError(res);
            setError(true);
          }
        });
      } else {
        data._method = "PUT";
        api.update_admin(admin?.id, data).then((res) => {
          if (res?.status === "success") {
            setError(res);
            setError(true);
            setTimeout(() => {
              navigate("/admin/list");
            }, 800);
          } else {
            setError(res);
            setError(true);
          }
        });
      }
    }
  };

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type="admin-list" />
      <Col xxl={7} lg={8} sm={10} xs={12} className="mx-auto p-3">
        <div style={{ minHeight: "73vh" }}>
          <div className="my-4">
            <h4>
              {" "}
              {type == "add"
                ? "Add   "
                : type == "view"
                ? "View "
                : "Edit "}{" "}
              User
            </h4>
          </div>
          <Row className="my-2">
            <Col sm={12} md={6} className="position-relative">
              <h6 className="">
                Employee Name
                {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div className="input-group my-3 cont-border">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="form-control border-0  py-2 px-3"
                  placeholder="Enter Employee Name"
                  aria-label="Employee Name"
                  disabled={type == "view" ? true : ""}
                />
              </div>
              {/* {error?.includes("name") && (
                <p className="red-text">Please Enter your Name</p>
              )} */}
              {name?.length == 0 && nameErr && (
                <p className="err-input pe-3" style={{ float: "right" }}>
                  Please Enter your Name
                </p>
              )}
            </Col>
            <Col sm={12} md={6} className="position-relative">
              <h6>
                First Name{" "}
                {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div className="input-group my-3 cont-border">
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="form-control border-0  py-2 px-3"
                  placeholder="Enter First Name"
                  aria-label="First Name"
                  disabled={type == "view" ? true : ""}
                />
              </div>
              {name?.length == 0 && nameErr && (
                <p className="err-input pe-3">Please Enter your First Name</p>
              )}
            </Col>
            <Col sm={12} md={6} className="position-relative">
              <h6>
                Last Name{" "}
                {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div className="input-group my-3 cont-border">
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="form-control border-0  py-2 px-3"
                  placeholder="Enter Last Name"
                  aria-label="Last Name"
                  disabled={type == "view" ? true : ""}
                />
              </div>
              {name?.length == 0 && nameErr && (
                <p className="err-input pe-3">Please Enter your Last Name</p>
              )}
            </Col>
            <Col sm={12} md={6} className="position-relative">
              <h6>
                Mobile {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div className="input-group my-3 cont-border">
                {/* <input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  className="form-control border-0  py-2 px-3"
                  placeholder="Mobile"
                  aria-label="Mobile"
                  disabled={display === "view" ? true : ""}
                /> */}
                <PhoneInput
                  country={"in"}
                  className="form-control border-0  py-2 px-3"
                  placeholder="Enter Mobile"
                  onChange={(e) => setMobile(e)}
                  value={mobile}
                  disabled={type == "view" ? true : false}
                />
                {/* <input
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    if (e.target.value.length !== 10) {
                      e.target.setCustomValidity("invalid Ext");
                    } else if (e.target.value.length == 10) {
                      e.target.setCustomValidity("");

                      setMobile(e.target.value);
                    }
                  }}
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                  maxlength={10}
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                  type="number"
                  className="form-control border-0  py-2 px-3"
                  placeholder="Enter Mobile"
                  aria-label="Mobile"
                  disabled={type == "view" ? true : ""}
                /> */}
              </div>
              {mobile?.length == 0 && mobileErr && (
                <p className="err-input pe-3">Please Enter your Mobile</p>
              )}
            </Col>
            <Col sm={12} md={6} className="position-relative">
              <h6>
                Roles {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div class="file-input  mb-3 mt-2 cont-border w-100 px-3">
                <select
                  className="w-100 border-0 py-2 pointer"
                  disabled={type == "view" ? true : ""}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value={""}>Select Role</option>
                  {roleList?.map((role, ind) => (
                    <option key={ind} value={role?.id}>
                      {role?.code}
                    </option>
                  ))}
                </select>
              </div>
              {role?.length == 0 && roleErr && (
                <p className="err-input pe-3">Select Role</p>
              )}
            </Col>
            <Col sm={12} md={6} className="position-relative">
              <h6>
                E-Mail {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div className="input-group my-3 cont-border">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={type == "view" ? true : ""}
                  type="email"
                  className="form-control border-0 py-2 px-3"
                  placeholder="Enter E Mail"
                  aria-label="E-Mail"
                />
              </div>
              {validEmail.test(email) == false && emailErr && (
                <p className="err-input pe-3">Please Enter your Email</p>
              )}
            </Col>
          </Row>
          <Row className="my-2">
            {/* <Col sm={12} md={6}>
              <h6>Password</h6>
              <div className="input-group my-3 cont-border">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={type == "view" ? true : ""}
                  type="password"
                  className="form-control border-0 py-2 px-3"
                  placeholder="Password"
                  aria-label="Password"
                />
              </div>
              {password?.length == 0 && passwordErr && (
                <p className="red-text">Please Enter your Password</p>
              )}
            </Col>
            <Col sm={12} md={6}>
              <h6>Confirm Passowrd</h6>
              <div className="input-group my-3 cont-border">
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={type == "view" ? true : ""}
                  type="password"
                  className="form-control border-0 py-2 px-3"
                  placeholder="Confirm Password"
                  aria-label="Confirm Password"
                />
              </div>
              {confirmPassword?.length == 0 && confirmPasswordErr && (
                <p className="red-text">Please Enter your Confirm Passowrd</p>
              )}
            </Col> */}
            <Col xs={12} md={6} className="position-relative">
              <h6>
                Factroy Name{" "}
                {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div class="file-input  mb-3 mt-2 cont-border w-100 px-3">
                <select
                  className="w-100 border-0 py-2 pointer"
                  onChange={(e) => setDesignation(e.target.value)}
                  value={designation}
                  disabled={type == "view" ? true : ""}
                >
                  <option>Select Factroy Name</option>
                  {desData?.map((item, ind) => (
                    <option key={ind} value={item?.value}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* {type !== "view" && (
                <button onClick={designationShowHandler} className="ad-depart ">
                  Add Factroy
                </button>
              )} */}
              {designation?.length == 0 && designationErr && (
                <p className="err-input pe-3" style={{ bottom: "-28px" }}>
                  Select Factroy
                </p>
              )}
            </Col>
            <Col xs={12} md={6} className="position-relative">
              <h6>
                Department{" "}
                {type !== "view" && <span className="red-text">*</span>}
              </h6>
              <div class="file-input  mb-3 mt-2 cont-border w-100 px-3">
                <select
                  className="w-100 border-0 py-2 pointer"
                  onChange={(e) => setDepartment(e.target.value)}
                  value={department}
                  disabled={type == "view" ? true : ""}
                >
                  <option>Select Department</option>
                  {depData?.map((item, ind) => (
                    <option key={ind} value={item?.value}>
                      {item?.value}
                    </option>
                  ))}
                </select>
              </div>
              {type !== "view" && (
                <button onClick={departShowHandler} className="ad-depart ">
                  Add Department
                </button>
              )}
              {department?.length == 0 && departmentErr && (
                <p className="err-input pe-3" style={{ bottom: "-28px" }}>
                  Select Department
                </p>
              )}
            </Col>

            {show && (
              <AddDescription
                type="factory"
                setShow={setShow}
                show={show}
                getDepartment={getDepartment}
              />
            )}
            {departShow && (
              <AddDescription
                type="department"
                setShow={setDepartShow}
                show={departShow}
                getDepartment={getDepartment}
              />
            )}
          </Row>

          {type == "view" ? null : (
            <Col xs={12}>
              <div className="my-4 d-flex justify-content-center justify-content-md-end">
                <button
                  disabled={btn ? true : false}
                  className="border-0 fs-14px fnt-fam-f2 px-3 py-2 bg-primary1 text-white"
                  onClick={submitHandle}
                >
                  {type === "add" ? "Add " : "Update "}
                  Admin
                </button>
              </div>
            </Col>
          )}
        </div>
      </Col>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default AdminAddForm;
