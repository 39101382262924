export const Logo = require("../img/logo.png");
export const loaderIcon = require("../img/loader.gif");
export const FavLogo = require("../img/fav.png");
export const showPwdImg = require("../img/show-password.png");
export const hidePwdImg = require("../img/hide-password.png");
export const DrivenLogo = require("../img/driven-logo.png");
export const Check = require("../img/check.png");
export const Reg = require("../img/reg.png");
// dashboard //
export const ProfileImg = require("../img/dashboard/profile.png");
export const CompanyImg = require("../img/dashboard/company.png");
export const Facebook = require("../img/dashboard/fb.png");
export const Linkedin = require("../img/dashboard/in.png");
export const Instagram = require("../img/dashboard/ins.png");
export const Twiter = require("../img/dashboard/tw.png");
export const Nodata = require("../img/dashboard/nodata.png");
export const Dekra = require("../img/dashboard/dekra.png");
export const Awrds = require("../img/dashboard/awards.png");
// dashboard  end//

// ad_dashboard
export const AdPie1 = require("../img/ad_pie_1.png");
export const AdPie2 = require("../img/ad_pie_2.png");
export const AdPie3 = require("../img/ad_pie_3.png");
export const LoginVideo = require("../img/pro_main_video.mp4");
