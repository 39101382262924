import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import TopBar from "../../../navbar/TopBar";
import LogInFooter from "../../../logincomponent/LogInFooter";
import TopHeader from "../../../navbar/TopHeader";
import GeneralFilter from "../../../component/GeneralFilter";
import GeneralPagination from "../../../component/GeneralPagination";
import { api } from "../../../constants/Services";
import eye_icon from "../../../assets/Icons/Normal Icons/Eye.svg";
import delete_icon from "../../../assets/Icons/Normal Icons/Delete.svg";
import edit_icon from "../../../assets/Icons/Normal Icons/Edit.svg";
import SearchIcon from "../../../assets/Icons/Normal Icons/Search.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  rolesList,
  role_detail,
  get_roles_status,
} from "../../../constants/constants/Storage/actions";
import Action_Icon from "../../../assets/Icons/Normal Icons/Ellipse.svg";
import { useNavigate } from "react-router";
import ErrorModel from "../../../component/errorModel";
import ReactPaginate from "react-paginate";

function AdminRoles() {
  const [searchValue, setSearchValue] = useState("");
  const permissions = localStorage.getItem("permission");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalError, setModalError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const roles = useSelector((state) => state.roleList);
  const login = useSelector((state) => state.login_detail);

  const renderedRoles =
    roles.length > 0 &&
    roles?.filter((role) => {
      return (
        role?.code
          .replace(/ /g, "")
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1
      );
    });
  // console.log("search-val", searchValue);
  // console.log("filter-roles", renderedRoles);
  // console.log("role-list-use-selector", roles);
  const [actionDropModal, setactionDropModal] = useState("");

  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [activePages, setActivePages] = useState();

  const getRoles = () => {
    let params;

    if (search !== "") {
      params = `?page=${page}&role=${search}`;
    } else {
      params = `?page=${pages}`;
    }

    api.get_roles(params).then((res) => {
      // console.log("role-list", res);
      if (res?.status === "success") {
        setList(res?.roles);
        setPageCount(res?.pagination?.total / res?.pagination?.per_page);
        setActivePages(res?.pagination);
        dispatch(rolesList(res?.roles));
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };
  const getRolesStatus = (id) => {
   
    api.get_roles_status(id).then((res) => {
      if (res?.status === "success") {
        getRoles()
      } else {
        setModalError(res);
        setErrorBox(true);
      }
    });
  };

  const roleViewHandler = (role_id, action) => {
    api.role_detail(role_id).then((res) => {
      if (res?.status === "success") {
        dispatch(role_detail(res));
        action === "view"
          ? navigate("/add-role", { state: { action: "view" } })
          : navigate("/add-role", { state: { action: "edit" } });
      }

      // console.log("role-detail", res);
    });
  };

  const handlePageChange = (selectedObject) => {
    if (search !== "") {
      setPage(selectedObject.selected + 1);
    } else {
      setPages(selectedObject.selected + 1);
    }
  };

  useEffect(() => {
    getRoles();
  }, [pages, search, page]);

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  return (
    <>
      <TopBar />
      {errorBox && (
        <ErrorModel
          error={modalError}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopHeader type="role" />
      <div style={{ minHeight: "80vh" }} className="w-90 px-2 py-3 my-2">
        <div className="d-flex flex-wrap my-2">
          <Col xs={12} sm={12} md={11} className="px-4">
            <div className="d-flex flex-wrap my-2 my-md-0 align-items-center">
              <div className="action-page-cont">
                <p>Show</p>
                <div className="page-status-cont">
                  <p
                    style={{
                      border: "1px solid #ced4da",
                      padding: "4px",
                      // margin: "10px 0px",
                      textAlign: "center",
                      width: "35px",
                      height: "28px",
                    }}
                  >
                    {list?.length}
                  </p>
                </div>
                <p>{activePages?.total} Results</p>
              </div>
              <Col
                xs={12}
                lg={4}
                md={8}
                className="d-flex my-1 ms-auto my-md-0"
              >
                <div className="search-box d-flex justify-content-between align-items-center px-2 w-100 rounded-1">
                  <input
                    placeholder="Search Title"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img className="search-icon" src={SearchIcon} alt="searBar" />
                </div>
              </Col>
            </div>
          </Col>
          {permissions?.includes("ADD-ROLES") && (
            <Col xs={12} sm={12} md={1}>
              <button
                // style={{ height: "35px", minWidth: "88px" }}
                className="border-0 fs-14px fnt-fam-f2 bg-primary1 text-white py-2"
                onClick={() =>
                  navigate("/add-role", { state: { action: "create" } })
                }
              >
                Add Role
              </button>
            </Col>
          )}
        </div>
        <Col className="prduct-page">
          <div className="product-table asn-table px-0 mx-0 my-3 overflox-x-scroll min-ht-80">
            <table className="w-100" style={{ minWidth: "750px" }}>
              <thead>
                <tr>
                  <th className="text-center fs-15px">S.N</th>
                  <th className="text-center fs-15px">Role</th>
                  {/* <th className="text-center fs-15px">Type</th> */}
                  {permissions?.includes("STATUS-ROLES") && (
                    <th className="text-center fs-15px">Status</th>
                  )}
                  {permissions?.includes("EDIT-ROLES") && (
                    <th className="text-center fs-15px">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {
                  // login?.permissions.includes("CAN-LIST-ROLES") &&
                  list?.map((role, ind) => (
                    <>
                      <tr key={role?.id} className="shipment-table">
                        <td className="text-center fs-14px">{ind + 1}</td>
                        <td className="text-center fs-14px">{role?.code}</td>
                        {/* <td className="text-center fs-14px">
                          {role?.type == 1 ? "Admin" : "Suppliers"}
                        </td> */}
                        {permissions?.includes("STATUS-ROLES") && (
                          <td className="text-center">
                            <button
                              type="button"
                              style={{ width: "104px" }}
                              onClick={()=>getRolesStatus(role?.id)}
                              className={
                                role.status == 1
                                  ? `btn btn-outline-success  po-card-btn fs-14px`
                                  : `btn btn-outline-danger po-card-btn fs-14px`
                              }
                            >
                              {role.status == 1 ? "Active" : "In Active"}
                            </button>
                          </td>
                        )}
                        <td>
                          <div className="d-flex gap-3 action-box justify-content-center pointer">
                            {permissions.includes("VIEW-ROLES") && (
                              <img
                                src={eye_icon}
                                alt="eye-icon"
                                onClick={() =>
                                  roleViewHandler(role?.id, "view")
                                }
                              />
                            )}

                            {/* {login?.permissions.includes("CAN-EDIT-ROLES") && (  */}
                            {permissions?.includes("EDIT-ROLES") && (
                              <img
                                src={edit_icon}
                                alt="edit-icon"
                                onClick={() =>
                                  roleViewHandler(role?.id, "edit")
                                }
                              />
                            )}
                            {/* )} */}

                            {/* {login?.permissions.includes(
                              "CAN-DELETE-ROLES"
                            ) && <img src={delete_icon} alt="delete-icon" />} */}
                          </div>
                        </td>
                      </tr>
                    </>
                  ))
                }
              </tbody>
            </table>
          </div>
        </Col>
        <div className="page-inner-cont py-3 d-flex justify-content-end">
          <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
      <div className="rg-footer">
        <LogInFooter />
      </div>
    </>
  );
}

export default AdminRoles;
