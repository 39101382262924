import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { api } from "../../../constants/Services";
import TopBar from "../../../navbar/TopBar";
import ErrorModel from "../../../component/errorModel";
import TopHeader from "../../../navbar/TopHeader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function ViewSupplier() {
  const location = useLocation();
  const permissions = localStorage.getItem("permission");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [errorBox, setErrorBox] = useState(false);
  const [checked, setChecked] = useState([]);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [id, setId] = useState("");
  const [status, setStatus] = useState();
  const [factoriesList, setFactoriesList] = useState([]);
  const [btn, setBtn] = useState(false);
  const [list, setList] = useState([]);
  // console.log("location", location);
  const data = location?.state?.data;
  const [bussinessSource, setbussinessSource] = useState();
  const [category, setCategory] = useState();
  const [city, setCity] = useState({
    city: "",
    country: "",
    state: "",
  });

  const getTypes = () => {
    api.typesList().then((res) => {
      if (res?.status == "success") {
        setList(res);
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  useEffect(() => {
    getTypes();
  }, []);

  console.log("data", data);

  useEffect(() => {
    if (data) {
      let bussiness_source = list?.bussiness_source_types?.find(
        (i) => i?.id == data?.SourceID
      );

      if (bussiness_source) {
        setbussinessSource(bussiness_source?.name);
      }
      let categorys = list?.supplier_categories?.find(
        (i) => i?.code == data?.SupplierCategory
      );
      if (categorys) {
        setCategory(categorys?.name);
      }

      let citys = list?.cities?.find((i) => i?.city_id == data?.CityID);

      let countrys = list?.countries?.find(
        (i) => i?.country_id == data?.CountryID
      );

      let states = list?.states?.find((i) => i?.state_id == data?.StateID);

      setCity({
        city: citys?.city_name,
        country: countrys?.country_name,
        state: states?.state_name,
      });
    } else {
      navigate("/new-supplier");
    }
  }, [list]);
  const new_suppliers_list = useSelector(
    (state) => state.new_supplier_list_show
  );
  const factories_list = useSelector((state) => state.factories_list);

  const get_factores = () => {
    let temp = [];
    api.get_factories().then((res) => {
      if (res?.status == "success") {
        let responce = res?.factories?.filter((i) => i?.id !== 1);
        if (responce) {
          responce?.map((item) => {
            temp.push(item);
          });

          setFactoriesList(res?.factories);
        }
      } else {
        setError(res);
        setErrorBox(true);
      }
    });
  };

  const newSupplierModelClickHandler = (action) => {
    action === "submit" ? supplierStatusHandler(4) : setShow(false);
  };
  const checkBoxHandlers = (e) => {
    // console.log("check", e.target.value);
    let id = e.target.value;
    if (checked.includes(id)) {
      let remove_duplicate = checked.splice(checked.indexOf(id), 1);
      // console.log("tes", remove_duplicate);
      setChecked(checked);
    } else {
      setChecked((checked) => [...checked, id]);
    }
  };

  const checkBoxHandler = (id) => {
    let checkboxs = [...checked];

    if (checkboxs.includes(id)) {
      let ids = checkboxs.indexOf(id);
      checkboxs.splice(ids, 1);
      setChecked(checkboxs);
    } else {
      checkboxs.push(id);
      setChecked(checkboxs);
    }
  };

  const supplierStatusHandler = (status) => {
    let supplier_data = {
      id: id || "",
      status: status || "",
      factories: checked || [],
    };
    // console.log(
    // "🚀 ~ file: ViewSupplier.js:45 ~ supplierStatusHandler ~ supplier_data :",
    // supplier_data
    // );

    let formdata = new FormData();

    formdata.append("id", data?.id);
    formdata.append("status", status);

    if (checked) {
      checked?.map((item, i) => {
        formdata.append(`factories[${i}]`, item);
        // formdata.append(`merged_factories[${i}]`, item);
      });

      let inActive = factoriesList?.filter((i) => !checked?.includes(i?.id));

      inActive?.map((item, ind) => {
        formdata.append(`inactive_factories[${ind}]`, item?.id);
      });
    }

    setBtn(true);
    api.new_supplier_status_change(formdata).then((res) => {
      if (res?.status === "success") {
        setShow(false);
        setError(res);
        setErrorBox(true);
        setBtn(false);
        setTimeout(() => {
          if (status == 5) {
            navigate("/new-supplier");
          } else {
            navigate("/existing-supplier");
          }
        }, 800);
      } else {
        setShow(false);
        setBtn(false);
        setError(res);
        setErrorBox(true);
        setChecked([]);
      }
    });
  };

  const newSupplierStatusHandler = (sel_id, action) => {
    if (action === "accept") {
      setShow(!show);
      setId(+sel_id);
      setStatus(4);
    } else {
      setId(+sel_id);
      setStatus(5);
      supplierStatusHandler();
    }
  };

  const errorModalClose = () => {
    setErrorBox(!errorBox);
  };

  useEffect(() => {
    get_factores();
  }, []);

  // console.log("data", data);

  return (
    <>
      {errorBox && (
        <ErrorModel
          error={error}
          errorBox={errorBox}
          errorModalClose={errorModalClose}
        />
      )}
      <TopBar />
      <TopHeader type="new-supplier" />
      <Col xxl={8} sm={10} xs={12} className="mx-auto m-3 p-3">
        <Row className="d-flex">
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5 text-md-center">
              First Name
            </label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.first_name}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Last Name</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.last_name}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">
              Supplier Name
            </label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.supplier_name}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Short Name</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.short_name}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">
              Company Email
            </label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.email}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Category</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {category == "M"
                ? "Material"
                : category == "L"
                ? "Leather"
                : category == "ML"
                ? "Material & Leather"
                : ""}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5 ">
              Business Source
            </label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {bussinessSource ? bussinessSource : ""}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Email Id</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.primary_email}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Mobile No</label>
            {/* <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.phone}
            </p> */}
            <fieldset disabled>
              <PhoneInput
                className="border-btm"
                disableDropdown
                value={data?.telephone_code + data?.phone}
              />
            </fieldset>
          </Col>
          {/* <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Telephone No</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.telephone_code + " " + data?.telephone}
            </p>
          </Col> */}
          <Col lg={6} md={10} xs={12} className="pe-3 my-2 position-relative">
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Telephone No</label>
            <fieldset disabled>
              <PhoneInput
                className="border-btm"
                disableDropdown
                value={data?.telephone_code + data?.telephone}
              />
            </fieldset>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">
              {data?.CountryID == 1 ? "MSME No" : "IE Code"}
            </label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.msme_no}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">
              Company Website
            </label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.website}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5">Address</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {data?.address1}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5 ">City</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {city?.city}
            </p>
          </Col>

          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5 ">State</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {city?.state}
            </p>
          </Col>
          <Col lg={6} md={10} xs={12}>
            <label className="fnt-fam-f2 my-1 pt-1 t6 fs-5 ">Country</label>
            <p className="fnt-fam-f1 my-1 pt-1 t6 fs-6 border-btm">
              {city?.country}
            </p>
          </Col>
        </Row>
        <div className="d-flex my-5 justify-content-center justify-content-md-end">
          <button
            className="btn btn-outline-success btn-sm me-2"
            onClick={() => setShow(true)}
          >
            Approve
          </button>
          <button
            className="btn btn-outline-danger btn-sm ms-3"
            onClick={() => setShows(true)}
            disabled={btn ? true : false}
          >
            Reject
          </button>
        </div>
      </Col>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header
          closeButton
          className="border-0 text-center"
        ></Modal.Header>
        <Modal.Body className="py-1">
          <h5 className="w-100 text-center">
            Choose Factories you want to Approve
          </h5>
          <div className="d-flex flex-column align-items-center my-4">
            {factoriesList?.map((factory) => (
              <div className="d-flex w-50 justify-content-between">
                <label>{factory?.code}</label>
                <input
                  value={factory?.id}
                  type="checkbox"
                  onChange={(e) => checkBoxHandler(factory?.id)}
                  checked={checked?.includes(factory?.id)}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
              setChecked([]);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => supplierStatusHandler(4)}
            disabled={btn ? true : false}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={shows} onHide={() => setShows(false)} centered>
        <Modal.Header
          closeButton
          className="border-0 text-center"
        ></Modal.Header>
        <Modal.Body className="py-1">
          <h5 className="w-100 text-center">Do You Want Reject This Account</h5>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setShows(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => supplierStatusHandler(5)}
            disabled={btn ? true : false}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewSupplier;
