import PrepareResponse from "./prepareResponse";
import HandleException from "./handleException";
import httpClient from "../../constants/HttpClient/httpClient";

//  POST METHOD

export const post = async (url, data) => {
  try {
    let res = await httpClient.post(url, data);
    return PrepareResponse(res);
  } catch (error) {
    // return HandleException(error);
    return error;
  }
};

// GET METHOD

export const get = async (url, data) => {
  try {
    let res = await httpClient.get(url);
    return PrepareResponse(res);
  } catch (error) {
    return error;
  }
};

// DELETE METHOD

export const deletes = async (url, data) => {
  try {
    let res = await httpClient.delete(url);
    return PrepareResponse(res);
  } catch (error) {
    // return HandleException(error);
    return error;
  }
};
