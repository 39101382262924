const factories_list = (state = [], action) => {
  switch (action.type) {
    case "factories_list_store":
      return action.payload;
    default:
      return state;
  }
};

export default factories_list;
