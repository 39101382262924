import moment from "moment/moment";
import Badge from "react-bootstrap/Badge";

function User_Dash_Table({
  dummy_data,
  num,
  data,
  asnList,
  grnList,
  dcList,
  rnList,
}) {
  return (
    <div className="dashbord-card-cont mid-sc-res">
      <div className="px-4 table-scroll-horizontal over-flow-x-scroll my-2 w-100">
        <table className="table table-borderless min-w-1kpx">
          <thead className="fs-13px fnt-fam-f1 dash-th-hgt">
            <tr>
              {dummy_data.map((title, ind) => {
                return (
                  <th className="text-center text-nowrap px-0" key={ind}>
                    {title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.factory?.FactoryName}</td>
                    <td>
                      {item?.LeatherPurchaseOrderID ||
                        item?.MaterialPurchaseOrderID}
                    </td>
                    <td>
                      {item?.LeatherPurchaseOrderNo ||
                        item?.MaterialPurchaseOrderNo}
                    </td>
                    {/* <td>{"-"}</td> */}
                    <td>{item?.supplier?.SupplierName}</td>
                    <td>
                      {item?.LeatherPurchaseOrderDate
                        ? moment(item?.LeatherPurchaseOrderDate).format(
                            "DD-MMM-YYYY"
                          )
                        : "-"}
                    </td>
                    <td>
                      {item?.type == "L"
                        ? "Leather"
                        : item?.type == "M"
                        ? "Material"
                        : "-"}
                    </td>
                    {/* <td>{item?.date}</td> */}
                    <td>
                      {item?.Approval == "A"
                        ? "Approved"
                        : item?.Approval == "C"
                        ? "Cancelled"
                        : "-"}
                    </td>
                  </>

                  {/* <td>
                    {num == 0
                      ? "007"
                      : num == 1
                      ? "007"
                      : num == 2
                      ? "007"
                      : num == 3
                      ? "007"
                      : num == 4
                      ? "007"
                      : num == 5
                      ? "007"
                      : ""}
                  </td>
                  <td className="px-0">
                    {num == 0
                      ? "#123456"
                      : num == 1
                      ? "#12344"
                      : num == 2
                      ? "#12344"
                      : num == 3
                      ? "#12344"
                      : num == 4
                      ? "#12344"
                      : num == 5
                      ? " "
                      : ""}
                  </td>
                  <td className="px-0">
                    {num == 0
                      ? "28.01.2023"
                      : num == 1
                      ? "28.01.2023"
                      : num == 2
                      ? "28.01.2023"
                      : num == 3
                      ? "28.01.2023"
                      : num == 4
                      ? "28.01.2023"
                      : num == 5
                      ? " "
                      : ""}
                  </td>
                  {num == 0 ? null : (
                    <td className="px-0">
                      {
                        // num == 0
                        //   ? "John"
                        //   :
                        num == 1
                          ? "678-1"
                          : num == 2
                          ? "9286827"
                          : num == 3
                          ? "9286827"
                          : num == 4
                          ? "2785672"
                          : num == 5
                          ? "87829"
                          : ""
                      }
                    </td>
                  )}

                  <td className="px-0">
                    {num == 0
                      ? "Material"
                      : num == 1
                      ? "Material"
                      : num == 2
                      ? "678-1"
                      : num == 3
                      ? "678-1"
                      : num == 4
                      ? "98687"
                      : num == 5
                      ? " "
                      : ""}
                  </td>
                  <td className="px-0">
                    {num == 0
                      ? "28.01.2024"
                      : num == 1
                      ? "2"
                      : num == 2
                      ? "Material"
                      : num == 3
                      ? "Material"
                      : num == 4
                      ? "678-1"
                      : num == 5
                      ? "Material"
                      : ""}
                  </td>
                  {num == 2 ? (
                    <>
                      <td>{"05/28/2023"}</td>
                      <td>{"08/28/2023"}</td>
                    </>
                  ) : null}

                  {num == 3 ? (
                    <>
                      <td>{"0976"}</td>
                    </>
                  ) : null}

                  {num == 4 ? (
                    <>
                      <td>{"Material"}</td>
                    </>
                  ) : null} */}

                  {/* <td></td> */}
                  {/* {num == 3 ? null : (
                    <td className="px-0">
                      {num == 0 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary po-card-btn fs-14px"
                          >
                            Status Received
                          </button>
                          <Badge bg="secondary" className="ms-2 py-1">
                            New
                          </Badge>
                        </>
                      ) : num == 1 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary po-card-btn fs-14px"
                          >
                            Yet to Respond
                          </button>
                        </>
                      ) : num == 2 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary po-card-btn fs-14px"
                          >
                            Yet to Dispatch
                          </button>
                        </>
                      ) : num == 4 ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-secondary po-card-btn fs-14px"
                          >
                            Resolved
                          </button>
                        </>
                      ) : null}
                    </td>
                  )} */}
                </tr>
              );
            })}
            {asnList?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.FactoryName}</td>
                    <td>
                      {item?.CreatedDateTime
                        ? moment(item?.CreatedDateTime).format("DD-MMM-YYYY")
                        : "-"}
                    </td>

                    <td>{item?.purchase_order_id}</td>
                    <td>
                      {item?.LeatherPurchaseOrderNo ||
                        item?.MaterialPurchaseOrderNo ||
                        item?.purchase_order_no}
                    </td>
                    <td>{item?.type == "L" ? "Leather" : "Material"}</td>
                    <td>{item?.shipping_count ? item?.shipping_count : "-"}</td>
                    {/* <td>{item?.status}</td> */}
                  </>
                </tr>
              );
            })}
            {grnList?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.factory?.FactoryName}</td>
                    <td>
                      {item?.LeatherGoodsReceiptNoteNo ||
                        item?.MaterialGoodsReceiptNoteNo}
                    </td>
                    <td>
                      {item?.LeatherGoodsReceiptNoteDate
                        ? moment(item?.LeatherGoodsReceiptNoteDate).format(
                            "DD-MMM-YYYY"
                          )
                        : item?.MaterialGoodsReceiptNoteDate
                        ? moment(item?.MaterialGoodsReceiptNoteDate).format(
                            "DD-MMM-YYYY"
                          )
                        : "-"}
                    </td>
                    {/* <td>{item?.status}</td> */}
                    <td>{item?.DCNo}</td>
                    <td>
                      {item?.type == "M"
                        ? "Material"
                        : item?.type == "L"
                        ? "Leather"
                        : "-"}
                    </td>
                    {/* <td>
                      {item?.Approval == "A"
                        ? "Approved"
                        : item?.Approval == "C"
                        ? "Cancelled"
                        : ""}
                    </td> */}
                  </>
                </tr>
              );
            })}
            {dcList?.map((item, ind) => {
              let temp = [];
              let splited;
              item?.leather_a_s_n_supplier_details?.map((po_number, numInd) =>
                temp.push(
                  po_number?.purchase_order_detail_del_date
                    ?.leather_purchase_order_detail?.LeatherPurchaseOrderID
                )
              );

              if (temp?.length > 0) {
                const separator = ",  ";
                const result = temp?.reduce(
                  (accumulator, currentValue) =>
                    accumulator + separator + currentValue
                );
                splited = result;
              } else {
                splited = "";
              }
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.factory?.FactoryName}</td>
                    <td>{item?.DCNNo ? item?.DCNNo : "-"}</td>
                    <td>
                      {item?.DCDate
                        ? moment(item?.DCDate).format("DD-MMM-YYYY")
                        : "-"}
                    </td>
                    <td>{temp?.length > 0 ? splited : "-"}</td>
                    <td>{item?.InvoiceNo ? item?.InvoiceNo : "-"}</td>
                    <td>
                      {item?.MaterialType == "L"
                        ? "Leather"
                        : item?.MaterialType == "M"
                        ? "Material"
                        : "-"}
                    </td>
                    {/* <td>{item?.status}</td>
                    <td>{item?.status}</td>
                    <td>{item?.status}</td> */}
                  </>
                </tr>
              );
            })}

            {rnList?.map((item, ind) => {
              return (
                <tr
                  className="text-center fs-13px fnt-fam-f1 dash-tr-hgt"
                  key={ind}
                >
                  <>
                    <td>{ind + 1}</td>
                    <td>{item?.FactoryName}</td>
                    <td>{item?.ReturnToSupplierNo}</td>
                    <td>
                      {item?.ReturnToSupplierDate
                        ? moment(item?.ReturnToSupplierDate).format(
                            "DD-MMM-YYYY"
                          )
                        : "-"}
                    </td>
                    {/* <td>{item?.status}</td> */}
                    <td>{item?.GRNNo ? item?.GRNNo : "-"}</td>
                    <td>{item?.DCNo ? item?.DCNo : "-"}</td>
                    <td>
                      {item?.type == "M"
                        ? "Material"
                        : item?.type == "L"
                        ? "Leather"
                        : "-"}
                    </td>
                  </>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default User_Dash_Table;
